// Corelayer overrides for Masterbrand Accordion

.accordion-masterbrand__title {
    width: auto;
    float: none;
    clear: none;

    &:hover,
    &:focus {
        &:not(.accordion-disabled) {
            color: $primary-color;
        }
    }
}

.accordion-masterbrand__content {
    margin: 0;
}
