.prefooter {
  &-module {
    padding: 1rem;
    width: 100%;
    max-width: $global-width;
    margin: 0 auto;
    left: 0;
    right: 0;
    &-interact {
      @include grid-row;
      h3 {
        @include fluid-type('font-size', 40rem, 80rem, 1.1875rem, 1.5rem);
        margin-bottom: 0;
      }
      .cta-row-link {
        padding: .7em .5em;
        color: $black;
        border-color: $black;
        &:focus,
        &:hover {
          color: $white;
      }
     }
      &-social {
        @include breakpoint('medium') {
          @include grid-column(20%, $global-margin);
        }
        @include breakpoint('large') {
          @include grid-column(28%, $global-margin);
          margin-top: 0;
        }
        h3 {
          color: $white;
          @include breakpoint('medium') {
          color: $primary-color-light;
        }
        }
      }
      &-feedback {
        width: 100%;
        margin-top: 1rem;
        @include breakpoint('medium') {
          @include grid-column(80%, $global-margin);
          margin-top: 0;
        }
        @include breakpoint('large') {
          @include grid-column(72%, $global-margin);
          margin-top: 0;
        }
        form {
          margin-top:.5rem;
          > div {
            margin-bottom: 0;
            &:nth-child(1) {
              width: 40%;
              min-width: 10em;
              float:left;
              background: $white;
            }
            &:nth-child(2) {
              float:left;
            }
          }
        input, textarea {
          height: 2.85rem;
          &:focus + label {
            color: $white;
           }
          }
        }
        .form-actions {
          padding-top: 0 !important;
          float: left;

          input[type=submit],
          button[type=submit] {
          width: 28%;
          font-size: 1rem;
          margin-right:0;
          height: auto;
          padding: .8rem .5rem .76rem .5rem;
          //margin-top:-1.7rem;
          &:hover {
              color: $white;
          }
         }
        }
    }
    &-text {
      width: 100%;
      float: left;
      text-align: center;
      margin-left:0;
      @include breakpoint('medium') {
      text-align: left;
      margin-right: $global-margin;
      width: 27%;
      @include breakpoint('large') {
        width: 27%;
      }
    }
  }
   }
  }
}

.prefooter-module-interact-social {
  .social-bar {
      border-bottom: 1px solid $darkest-gray;
      @include breakpoint('medium') {
        border-right: 1px solid $darkest-gray;
        border-bottom:none;
    }
    a {
      margin-top: .5rem;
      .svg-icon {
            font-size: 1.2rem;
            color: $primary-color-light;
            @include breakpoint('medium') {
            color: $white;
           }
          }
      }
  }
}
