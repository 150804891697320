.tile-row {
  &-module {
      &.bordered {
        border-top: 1px solid $medium-gray;
        div:first-of-type {
            margin-top: $global-margin;
        }
    }
    width: calc(100% + 2rem);
    position: relative;
    left: -2rem;
    @include breakpoint(medium) {
      width: 100%;
      left: 0;
      .with-previous & {
        padding-top: 3rem;
      }
    }
    @include breakpoint(large) {
      .with-previous & {
        padding-top: 4.6rem;
      }
    }
  }
  &-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  &-list {
    margin: 0 1rem;
    // @include grid-layout(4, '.tile-row-list-item', 0);
    @include inline-grid;
    .tile-row-list-item {
      width: 25%;
      display: inline-block;
    }
    min-width: 70em;
    @include breakpoint(medium) {
      text-align: center;
      margin: 0;
      min-width: rem-calc(map-get($breakpoints, 'medium'));
    }
    &-item {
      padding-top: 17%;
    }
  }
}
