.stat-header {
  &-module {
    position: relative;
    width: 100%;
    background-color: $black;
    opacity: .9;
    height: auto;
    @include breakpoint(medium) {
      height: 85vh;
      min-height: 40em; // for iOS and safari
      //overflow: hidden;
    }
    &-bg {
      &-mobile {
        height: 70vh;
        min-height: 25em; // for iOS and safari
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        @include breakpoint(medium) {
          display: none;
        }
      }
      &-desktop {
        display: none;
        @include breakpoint(medium) {
          display: block;
          height: 85vh;
          min-height: 40em;// for iOS and safari
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          margin-bottom:1rem;
        }
      }
    }
    &-titles {
      color: $white;
      text-align: center;
      position: absolute;
      top: 8vh;
      top: 8%; // for iOS and safari
      left: 5%;
      width: 90%;
      height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(medium) {
        display: block;
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%); // for iOS and safari
        -ms-transform: translateY(-100%); // for iOS and safari
        -o-transform: translateY(-100%); // for iOS and safari
        -moz-transform:: translateY(-100%); // for iOS and safari
        top: 35%;
        height: auto;
      }
    }
    &-info {
      padding:0;
      margin: 0;
      @include breakpoint(medium) {
        position: absolute;
        top: 35%;
        left: 5%;
        padding: 0 15% 1% 15%;
        width: 90%;
        text-shadow: 0 0 1rem $black;
      }
    }
    &-title {
      color: $white;
      text-shadow: 0 0 2rem $black;
      margin-top: .2rem;
      line-height: 1;
      @include fluid-type('font-size', 40rem, 80rem, 2.1rem, 3.125rem);
      @include breakpoint(medium) {
        padding: 0 18%;
      }
    }
    &-description {
      @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.5rem);
      font-weight: 300;
      padding: 0;
      line-height:1.5;
      margin: .5rem;
      text-align: center;
      color: $white;
      @include breakpoint(medium) {
        margin-bottom: 1rem;
        font-weight: 500;
        color: $white;
      }
    }
    &-actions {
      position: absolute;
      top: 42%;
      left: 5%;
      width: 90%;
      text-align: center;
      padding: 0 10%;
      @include breakpoint(medium) {
        z-index: auto;
        position: relative;
        right: auto;
        bottom: auto;
        text-align: center;
    }
      &-apply {
        display: inline-block;
        box-shadow: 0 0 .5em rgba($black, .5);
        margin-bottom: 1rem;
        display: block;
        &:before {
          font-family: fontawesome;
          font-weight: 100;
          display: inline-block;
          margin-right: .5em;
        }
        &:focus,
        &:hover {
          text-decoration: none;
        }
      }
      &-apply {
        text-shadow: none;
        &:focus,
        &:hover {
          color: $primary-color-light;
          background: $black;
        }
      }
    }
    &-stats {
      @include inline-grid;
      text-align: center;
      padding: 0;
      @include breakpoint(medium) {

        width: 100%;
        margin: 0 auto;
        max-width: 40rem;
        padding: 1rem 0 2rem 0;
      }
    }
    &-stat {
      position: relative;
      color: $white;
      margin-bottom:1rem;
      &:first-child {
        width: 100%;
      }
      &:nth-last-child(n+4){
        width: 50%;
      }
      width: 50%;
      height: 0;
      padding-top: 20%;
      @include breakpoint(medium) {
        &:first-child {
          width: 25%;
        }
        &:nth-last-child(n+4){
          width: 25%;
        }
        width: 25%;
        padding-top: 25%;
        color: $white;
      }
      &-content {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        color: $white;
        @include breakpoint(medium) {
          @include fluid-type('font-size', 40rem, 80rem, .8rem, 1.6rem);
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%); // for iOS and safari
          -ms-transform: translate(-50%, -50%); // for iOS and safari
          -o-transform: translate(-50%, -50%); // for iOS and safari
          -moz-transform:: translate(-50%, -50%); // for iOS and safari
          // max-width: 7em;
          // max-height: 7em;
          width: 90%;
          height: 90%;
          padding: 0 1.5rem;
          color: $white;
          box-shadow: inset 0 0 2rem rgba(70,70,70,.2), 0 0 1rem rgba(70,70,70,.2);
          border-radius: 50%;
          border: 2px solid #fff;
          font-size: .8rem;
        }
      }
      &-number {
        font-family: $header-font-family;
        font-size: 2em;
        @include breakpoint(medium) {
          position: absolute;
          bottom: 50%;
          left: 5%;
          width: 90%;
          text-shadow: 0 0 2rem $black;
        }
        img {
          max-height: 70px;
          @include breakpoint(medium) {
          max-height: 90px;
        }
        }
      }
      &-icon {
        font-size: 1.5rem;
        @include breakpoint(medium) {
        font-size: 2rem;
      }
      }
      &-title {
        letter-spacing: $header-letterspacing;
        text-transform: uppercase;
        font-size: .7em;
        line-height: 1.2;
        font-weight: 400;
        @include breakpoint(medium) {
          font-size: .6em;
          font-weight: 600;
          position: absolute;
          letter-spacing: .15rem;
          top: 50%;
          left: 5%;
          width: 90%;
          text-shadow: 0 0 2rem $black;
        }
      }
    }
    .btn-primary {
      background: $primary-color-light;
      color: $black !important;
      border: $primary-color-light;
      &:focus,
      &:hover {
        color: $primary-color-light !important;
        background: $black !important;
      }
      &.large {
        font-size:inherit;
      @include breakpoint(medium) {
        font-size: 1.3rem;
        max-width: 320px;
      }
     }
    }
  }
}
