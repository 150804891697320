
.form-module,
.prefooter-module {
    form {
      > div {
        position: relative;
        overflow: hidden;
        margin-bottom: 1rem;
      }
      input, textarea {
        width: 100%;
        border: 1px solid lightgray;
        border-bottom: 2px solid lightgray;
        background: none;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 8px 12px;
        outline: 0;
        height: auto;
        min-height: 2.5rem;
        &:valid {
          // Hides the label
          background: none;
        }
        &:focus {
          border-color: #7dc04b;
          background: $white;
        }
        &:focus + label {
          background: $white;
          border-top: none;
          border-left: 1px solid $primary-color;
          border-right: 1px solid $primary-color;
          border-bottom: 3px solid $primary-color;
          color: $black;
          font-size: 70%;
          padding: 2px 6px;
          z-index: 2;
          text-transform: uppercase;
        }
      }
      input:-webkit-autofill {
        background-color: $lightest-gray;
      }
      label {
        transition: // not padding
          background 0.2s,
          color 0.2s,
          top 0.2s,
          bottom 0.2s,
          right 0.2s,
          left 0.2s;
        position: absolute;
        color: #999;
        padding: 0px 6px 0 6px;
      }
      textarea {
        display: block;
        resize: vertical;
      }
        input, textarea {
        padding: 10px 10px 0px 5px;
        margin-bottom:0;
      }
      label {
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size:.715rem;
        line-height: 1.3;
        letter-spacing: .3px;
        margin-top: .05rem;
      }
      input:focus, textarea:focus {
        padding: 4px 6px 19px 6px;
      }
      input:focus + label, textarea:focus + label {
        top: 100%;
        margin-top: -20px;
      }
      .form-actions {
        padding-top: $global-padding;
        margin: 0 auto;
        max-width: 20rem;
        &::before {
          content: none;
        }
        button[type=submit],
        input[type=submit] {
        min-width: 10em;
        width: 100%;
       }
      }
      .select {
        vertical-align: middle;
        &:before, &:after {
          content: "";
          position: absolute;
        }
        &:after {
          color: $darkest-gray;
          background: $primary-color;
          content: "\25BC";
          font-size: .9rem;
          padding: 0.5em 0.8em 0.6em 0.8em;
          pointer-events: none;
          right: 0;
          top: 0;
          vertical-align: middle;
          label {
            display:none;
          }
        }
        select {
          width: 100%;
          border: 1px solid lightgray;
          border-bottom: 2px solid lightgray;
          padding: 8px 12px;
          cursor: pointer;
          font-size: .9rem;
          color: $dark-gray;
          padding-right: 2em;
          top: 0;
          left: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          &:focus {
            outline: 1px solid $primary-color;
            border-bottom: 3px solid $primary-color;
          }
          option {
            background: $lightest-gray;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .input-validation-error {
      margin-top: 0;
      padding: 0;
    }
  }
  .contact-us-form-module {
    form {
      margin: 0 auto;
    }
  }

  .prefooter-module form input {
    min-height: 2.8rem;
  }


// error for form
.field-validation-error {
    font-size: .8rem;
    color: #af291a;
    display: block;
    padding:0;
    margin-top: 0;
}

  .input-validation-error {
    border: 1px solid #af291a;
}

// Form button
.form-actions {
  &::before {
    content: none;
  }
  button[type=submit],
  input[type=submit] {
  border-radius: 0;
  cursor: default;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: $primary-color;
  border: 2px solid $primary-color;
  color: $darkest-gray;
  font-weight: 600;
  text-align: center;
  height: 3rem;
  transition: background .25s ease;
  font-size: 1rem;
  &:hover {
      background: transparent;
      color: $darkest-gray;
  }
 }
}

//contact form
.contact-us-form,
.program-form {
  &.form-module {
    max-width:50rem;
    margin: 0 auto;
  }
}

.events-form {
  &.form-module {
    width: 100%;
  }
}
