﻿/// <reference path="../app" />

.image-gallery-thumbnail,
.image-gallery-carousel {
  img {
    width:100%;
    height:auto;
    max-height: 220px;
    display: block;
  }
}
.image-gallery {
  &-carousel {
    @include clearfix;
    max-height: 220px;


  &-container {
    margin: 0 2rem;
    display: block;
    padding: 0;
    position: relative;
    max-height: inherit;
   }
   .slick-slide {
     max-height: 220px;
     width: auto;
     margin-right: 4px;
     margin-left: 4px;
   }
 }

 &-thumbnail {
   display: block;
   float:left;
 }
}
