
*, *:before, *:after {
	box-sizing: border-box;
}

.nav, .nav li, .nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}


// Menu component
// ------------------------------------------------------------

.push-nav {
	position: relative;
	width: 100%;
    background: darken($black, 4%);
	color: #fff;
	z-index: 1001;
	

	> .nav-container {
		overflow: hidden;
		max-width:75rem;
		margin: 0 auto;
		transition: all 500ms ease-in-out;
		padding: 1rem .5rem;
		@include breakpoint(medium) {
			max-height: 30em;
			max-width:75rem;
		}
	}

	&.min > .nav-container {
		height: 0;
		max-height: 0;
		padding: 0;
		margin: 0;
	}
	
	&.full-screen {
		position: fixed;
		height: 100%;
		overflow-y: scroll;
		
		@include breakpoint(medium) {
			position: relative;
			overflow-y: hidden;
		
		}
	}

	.menu-close {
		right: 0;
		height: 40px;
		padding: 0 0 0 1rem;
		color: #777;
		font-size: 1.5em;
		line-height: 1;
		text-decoration: none;
		display: block;
		float: right;
		
		@include breakpoint(medium) {
			font-size: 2em;
		}
		
		&:hover, &:focus {
			color: $white;
		}
		

		
		span {
			text-transform: uppercase;
			font-size: .7rem;
			display: block;
			margin-left: -.5rem;
			letter-spacing: 1px;

		}
	}
	
	.nav-list {
		clear: both;
		padding: 2rem 0;
		border-top: 1px solid #3d3f40;
		  width: 100%;
		  @include breakpoint(medium) {
		  -webkit-columns: 4 100px;
		  -moz-columns: 4 100px;
		  columns: 4 100px;
		  -webkit-column-gap: 2em;
		  -moz-column-gap: 2em;
		  column-gap: 2em;
		  max-height: 22em;
		  height: 100%;
		  }
	}

	.nav {
		padding: .5rem;
		
		@include breakpoint(medium) {
			padding: 0;
			display: inline-block;
			vertical-align: top;
			width: 100%;


	   }   

		.nav-heading {
			margin-bottom: 0.5em;
			padding-left: 5px;
			color: $primary-color-light;
			font-size: 0.9rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			display:inline-block;
			@include breakpoint(medium) {
			width:100%;
			}
			
			&:hover, &:focus { 
				background: #222;
				color: #fff; 
				@include breakpoint(medium) {
				width:100%;
				}
			}
			
		}

		.show-for-small-only a {
			display:inline-block;
			float:right;
			right:0;
		    background: lighten($black, 4%);
	        padding: .5rem 1rem;
		} 
		
		a {
			display: block;
			padding: 5px;
			text-decoration: none;
			color: #ddd;

			&:hover, &:focus { 
				background: #222;
				color: $primary-color-light; 
				@include breakpoint(medium) {
				width:100%;
				}
			}

		}
		
		li {
			font-size: .9rem;
			letter-spacing: .5px;
		}
		
		&.no-float {
			float: none;
			clear:both;
			Display: block;
		}

	}


} // .menu


// Search 
// ------------------------------------------------------------


// Search
.search-container {
	padding: .5rem;
	
	@include breakpoint(medium) {
	max-width: 75rem;
	margin: 0 auto;	
	}
	
	.form-wrapper {
		width: 80%;
		float: left;
		@include breakpoint(medium) {
			width: 60%;
		}
		@include breakpoint(large) {
			width: 40%;
		}
	}
	
		
}

    .form-wrapper {
        width: 100%;
		
		
	  input {
        width: 80%;
        height: 40px;
        padding: 10px 5px;
		border: 1px solid $primary-color-light;
		border-right: none;
        float: left;    
		
		@include breakpoint(medium) {
			height: 45px;	
			width: 90%;
		}
		
	  &:focus {
        outline: 0;
        background: $white;
		box-shadow: none;
		}
		
		[type=text] {
			color: $white;
		}
	  }
	  
	  input:placeholder {
		color: white;
	  }
		
    }
 
    .form-wrapper input::-webkit-input-placeholder {
       color: $white;
       font-weight: normal;
    }
    
    .form-wrapper input:-moz-placeholder {
        color: $white;
        font-weight: normal;
    }
    
    .form-wrapper input:-ms-input-placeholder {
        color: $white;
        font-weight: normal;
    }  

	input[type="text"] {
       color: black;
    }
    
.form-wrapper button {
	overflow: visible;
	position: relative;
	float: right;
	border: 0;
	padding: 0;
	cursor: pointer;
	height: 40px;
	width: 20%;
	color: $black;
	background: $primary-color; 

	
	&:hover,
	&:active,
	&:focus{		
		background: lighten($black, 8%);		
		color: $primary-color-light;   
	}	
	
			@include breakpoint(medium) {
			height: 45px;	
			width: 10%;
		}
	
}   

// Nav functionality
// ------------------------------------------------------------

.nav-open {
	background: darken($black, 4%);
	 @include breakpoint(medium) {
		background: white;
	}
}

.nav-open .module-wrapper, 
.nav-open .module-container,
.nav-open .header-container {
	display: none;
		 @include breakpoint(medium) {
		display: block;
	 }
}


.show-panel {
	display:none;
	@include breakpoint(medium) {
		display:block;
	}
}

