﻿/// <reference path="app" />

$text-color: $black;
$text-color-light: $white;
/// Sizes for buttons.
/// @type Map
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 0.9rem,
  large: 1.75rem,
) !default;


// fluid type
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	& {
		@each $property in $properties {
			#{$property}: $min-value;
		}

		@media screen and (min-width: $min-vw) {
			@each $property in $properties {
				#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
		}

		@media screen and (min-width: $max-vw) {
			@each $property in $properties {
				#{$property}: $max-value;
			}
		}
	}
}


@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin hide-text() {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

@mixin inline-list() {
    margin-left: 0;
    list-style: none;
    color: $primary-color;
    > li {
        display: inline-block;
    }
}

@mixin inline-grid(){
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
    font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

    > * {
       display: inline-block;
       letter-spacing: normal;
       vertical-align: top;
       text-rendering: auto;
       font-family: $body-font-family;
    }
}


// link styles
@mixin link-chevron() {
    transition: all 0.3s ease;
    font-weight: 600;
        &:after {
          @include chevron;
           padding-left: .5rem;
           transition: transform 0.3s ease;
        }
        &:hover,
        &:focus   {
            &:after {
                transform: translateX(.3rem);
            }
        }
}

// callout box
@mixin callout () {
 padding: $global-padding*1.5;
 margin: $global-margin/2 0;
}

@mixin chevron-before-list () {
  ul {
    list-style: none;
    margin-left: .8rem;
  }
  li {
    position: relative;
    list-style: none;
    margin: .5rem;
    &:before {
      @include fa-icon();
      content: $fa-var-angle-right;
      left: -.9rem;
      top: .2rem;
      font-size:1.3rem;
      position: absolute;
      color: $primary-color;
    }
    a {
      color: $anchor-color;
    }
  }
}

@mixin chevron {
  @include fa-icon();
  content: $fa-var-angle-right;
  font-weight: 400;
  font-style: normal;
  font-size:1.1em;
  text-decoration: none;
  display: inline-block;
}
