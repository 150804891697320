.event-details {

 &-module {
  .module-title,
  .module-sub-title {
    text-align: left;
  }
  .module-title {
    margin-bottom: .7em;
  }
  .module-sub-title {
    line-height: 1.5;
    margin-bottom: 2em;
    @include breakpoint(medium) {
      margin-bottom: 4em;
    }
  }
  section {
    @include grid-row;
    margin: $global-margin 0;
  .event-details {
    &-col {
      @include grid-column(100%, 0);
      @include breakpoint(medium) {
      @include grid-column(50%, $global-margin*4);
        padding-left: 0;
        padding-right: $global-padding*4;
      }
     @include breakpoint(large) {
         @include grid-column(1/3, 0);
         &:nth-of-type(2) {
          @include grid-column(2/3, 0);
        }
      }
      &.detailed-info{
      padding: $global-padding;
      background: $lightest-gray;
      margin: $global-margin 0;
       @include breakpoint(medium) {
         padding: 1.5rem 3rem;
         margin: $global-margin 0 0 0;
         float: right;
     }
     }
    }
    &-img {
        margin: $global-margin 0;
    }
   }
 }
 form {
   input[type=text],
   input[type=email] {
       width: 100%;  // to overwrite FS
       display: block; // to overwrite FS
       margin-bottom: 1rem; // to overwrite FS
       float: none; // to overwrite FS
 }
   .form-actions {
     float: left;
     margin: 0;
     width:50%;
     button[type=submit],
     input[type=submit] {
     cursor: default;
     -webkit-appearance: none;
     -moz-appearance: none;
     background: $primary-color;
     border: 2px solid $primary-color;
     color: $darkest-gray;
     font-weight: 600;
     text-align: center;
     height: 3rem;
     transition: background .25s ease;
     font-size: 1rem;
     &:hover {
         background: transparent;
         color: $darkest-gray;
     }
    }
   }
  }
 }
 &-date {
    @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
    background: $unihall-pink;
    color: $white;
    font-weight: 500;
    max-width: 25em;
    letter-spacing: $header-letterspacing*2;
    margin-bottom: $global-margin/1.5;
    padding: $global-padding/2 $global-padding/4 $global-padding/2 $global-padding;
}
}


.detailed-info {
    font-size: 1rem;
    line-height: 1;
    &-heading {
    @include fluid-type('font-size', 40rem, 80rem, 1.2rem, 1.777rem);
    }
    &-emphasis {
        .detailed-info-value {
        font-size: 1.8em;
        color: $dark-gray;
        }
    }
    &-label {
        font-size: .9rem;
        margin-top: .9rem;
        margin-bottom:-.2rem;
        font-weight: 700;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &-static {
        overflow: hidden;
        > div {
        float: left;
        width: 50%;
        padding-right: .5rem;
        }
    }
        &-content {
        margin-bottom: $global-margin;
        &-icon {
            font-size: .9rem;
            margin-right: .3rem;
        }
    }
}
