$header-font-family: "Source Sans Pro", sans-serif;

html,
body {
    height: 100%;
}

body {
    background: white;
    font-family: $header-font-family; // override for Foundation Layer
}

.hide {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    margin: 0;
    margin-bottom: 0.5rem;
}

//
//
// SITE CONTAINER STYLING
//Skip to content
.skip {
    position: absolute;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    text-align: left;
    overflow: hidden;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: visible;
}

//Site container base - to accomodate header height
.container {
    @include clearfix;
    background: white;
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;
}

//Site container base - to accomodate header height
.site-content {
    padding-top: 66px;
}

// Responsiveness for navigation - alows the page to step aside - uses JS
.site {
    &-pusher,
    &-container {
        //height: 100%;
    }
    &-container {
        overflow: hidden;
    }
    &-pusher {
        transition: none;
        transform: none;
        background: none;
    }
    &-content {
        position: absolute;
        top: 66px;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

//
//
// START OF STYLING
// Fluid Modular Scale
h1 {
    @include fluid-type("font-size", 40rem, 80rem, 1.875rem, 3.125rem);
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    line-height: 1.2;
}

h2 {
    @include fluid-type("font-size", 40rem, 80rem, 1.75rem, 2.5rem);
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    line-height: 1.2;
}

h3 {
    @include fluid-type("font-size", 40rem, 80rem, 1.4375rem, 1.75rem);
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    line-height: 1.3;
}

h4 {
    @include fluid-type("font-size", 40rem, 80rem, 1.1875rem, 1.375rem);
    line-height: 1.3;
}

h5 {
    @include fluid-type("font-size", 40rem, 80rem, 1.0625rem, 1.1875rem);
    line-height: 1.4;
}
h6 {
    @include fluid-type("font-size", 40rem, 80rem, 1rem, 1.125rem);
    line-height: 1.5;
}
body {
    @include fluid-type("font-size", 40rem, 80rem, 1rem, 1.125rem);
    line-height: 1.5;
}

//// Heading types
// Heading colours
h1,
h2,
h3,
h4,
h5,
h6 {
    text-rendering: $header-text-rendering;
    margin-top: 0;
    margin-bottom: $header-margin-bottom;
    line-height: $header-lineheight;
    font-weight: 600;
    letter-spacing: $header-letterspacing;

    small {
        color: $header-color;
        line-height: 0;
    }
}

b {
    font-weight: 600;
}

//fine print  /*used in rich text editor css - if changes are made, they need to be updated in both places*/
.fine-print {
    @include fluid-type("font-size", 40rem, 80rem, 0.8rem, 0.9rem);
}

//abbreviation  /*used in rich text editor css - if changes are made, they need to be updated in both places*/
abbr,
abbr[title] {
    border-bottom: 2px dashed $primary-color;
    text-decoration: none;
}

//definition list  /*used in rich text editor css - if changes are made, they need to be updated in both places*/
dl {
    margin: 0 0 $global-margin * 4 0;
    padding: 0;
}
dt {
    display: block;
    font-weight: 500;
    margin-bottom: 0;
    @include breakpoint(medium) {
        float: left;
        clear: left;
        width: 11rem;
        text-align: right;
    }
}
dd {
    display: block;
    -webkit-margin-start: 20px;
    margin: 0 0 1rem 1rem;
    line-height: 1.5;
    font-weight: 300;
    @include breakpoint(medium) {
        -webkit-margin-start: 30px;
        margin: 0 0 1rem 11rem;
        padding-left: 1rem;
    }
}

::selection {
    color: $black;
    background: $anchor-color;
}

::-moz-selection {
    color: $black;
    background: $anchor-color;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $darker-gray !important;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: $darker-gray !important;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: $darker-gray !important;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: $darker-gray !important;
}

// tables
table tbody tr:nth-child(even) {
    background-color: $lightest-gray;
}
.stacked .responsive-header {
    color: $darkest-gray;
}
.stacked td:nth-child(-n + 2) {
    background: $light-gray;
}
.stacked tr {
    background: $lightest-gray;
}

// Button variations /*used in rich text editor css - if changes are made, they need to be updated in both places*/
//
// Base button style that over rides FS style
.btn {
    &::after {
        content: none;
    }
    &-primary-hollow,
    &-secondary-hollow {
        margin: 0;
        vertical-align: initial;
    }
}

.btn-chevron {
    &:after {
        @include chevron;
        vertical-align: -0.07em;
        padding-left: 0.5rem;
        transition: transform 0.3s ease;
    }
    &:hover,
    &:focus {
        &:after {
            transform: translateX(0.3rem);
        }
    }
}

// Circle check list
.circle-check-list {
    margin-left: 1.9rem;
    li {
        position: relative;
        list-style: none;
        margin: 0.5rem;
        &:before {
            @include fa-icon();
            background: none;
            content: $fa-var-check;
            color: $primary-color;
            left: -1.9rem;
            position: absolute;
            padding: 0.25rem;
            top: 0.1rem;
            border-radius: 50%;
            font-size: 0.95rem;
        }
    }
}

//Base lists
ol ol,
ol ul,
ul ol,
ul ul {
    margin-left: $list-nested-side-margin;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
}

// Links
a {
    text-decoration: none;

    &:visited {
        color: transparent;
    }
}

//Chevron first list
.chevron-before-list,
.double-chevron-before-link-list {
    @include chevron-before-list();
    a {
        color: $primary-color;
    }
}

//pdf-icon-link /*used in rich text editor css - if changes are made, they need to be updated in both places*/
.pdf-icon-link {
    &:before {
        @include fa-icon();
        content: $fa-var-file-pdf-o;
        color: $black;
        font-size: inherit;
        display: inline-block;
        padding-right: 0.5rem;
    }
    a {
        color: $anchor-color;
        font-size: inherit;
        position: relative;
    }
}

// Callout boxes /*used in rich text editor css - if changes are made, they need to be updated in both places*/
.callout {
    @include callout;
    border: none;
    .dark-bg {
        color: $white;
    }
}

.content-container {
    background: $white;
    height: 100%;
}

.heading-border {
    color: inherit;
}

[data-object-fit="cover"] {
    object-fit: cover;
}

.intro {
    @include fluid-type("font-size", 40rem, 80rem, 1.1rem, 1.5rem);
    font-family: $body-font-family;
    font-weight: 300;
}

// Blockquote  /*used in rich text editor css - if changes are made, they need to be updated in both places*/
blockquote {
    @include fluid-type("font-size", 40rem, 80rem, 1.1rem, 1.5rem);
    color: $darker-gray;
    font-family: $body-font-family;
    font-weight: 300;
    position: relative;
    margin: 0;
    padding: $global-padding * 2.5;
    border: none;
    width: 100%;
    p {
        margin: 0;
    }
    &:before {
        @include fluid-type("font-size", 40rem, 80rem, 3rem, 5rem);
        font-family: $body-font-family;
        font-weight: 600;
        content: "\201C";
        color: $primary-color-light;
        position: absolute;
        top: -0.1rem;
        left: -0.5rem;
    }
    &:after {
        @include fluid-type("font-size", 40rem, 80rem, 3rem, 5rem);
        font-family: $body-font-family;
        font-weight: 600;
        content: "\201D";
        color: $primary-color-light;
        position: absolute;
        bottom: -1rem;
        right: 1rem;
    }
}

cite {
    @include fluid-type("font-size", 40rem, 80rem, 0.85rem, 0.912rem);
    padding-top: $global-padding;
    font-weight: 600;
    color: $dark-gray;
    font-style: normal;
    display: block;
    &:before {
        content: none;
    }
}

figcaption {
    @include fluid-type("font-size", 40rem, 80rem, 0.8rem, 0.9rem);
    text-align: center;
    padding-top: $global-padding;
    color: $dark-gray;
    font-style: normal;
}

// code /*used in rich text editor css - if changes are made, they need to be updated in both places*/
code {
    background: $light-gray;
    font-size: 1rem;
    border: none;
}

//figure image floats /*used in rich text editor css - if changes are made, they need to be updated in both places*/
figure {
    &.float-left {
        margin: 0 auto;
        @include breakpoint(medium) {
            margin: $global-margin/2 $global-margin * 2 $global-margin 0;
        }
    }
    &.float-right {
        margin: 0 auto;
        @include breakpoint(medium) {
            margin: $global-margin/2 0 $global-margin $global-margin * 2;
        }
    }
    &.figure-wide {
        margin: $global-margin 0;
    }
}

.figure-wide {
    & img {
        max-width: 100%;
        width: 100%;
        margin: 0;
    }
}

.float-center {
    & img {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}
