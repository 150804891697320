.news-detail-module {
  .module-title,
  .module-sub-title {
    text-align: left;
  }
  .module-title {
    margin-bottom: .7em;
  }
  .module-sub-title {
    line-height: 1.5;
    margin-bottom: 2em;
    @include breakpoint(medium) {
      margin-bottom: 4em;
    }
   }
  }


.news-detail {
    margin-top: $global-margin;

    &-module {
      margin-top: -$global-margin*3;
    }
    &-date {
       @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
       background: $unihall-blue;
       color: $white;
       font-weight: 500;
       width: 13em;
       letter-spacing: $header-letterspacing*2;
       text-transform: uppercase;
       margin-bottom: $global-margin/1.5;
       padding: $global-padding/2 $global-padding/4 $global-padding/2 $global-padding;
   }

   &-img {
       margin: $global-margin 0;
   }
}
