@import 'includes';

.panels {
  @include clearfix;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  &.item-count-1 {
    @include breakpoint('medium') {
      flex-wrap: nowrap;
      @include grid-layout(1, '.panels-item', $global-margin);
    }
    .panels-item {
      max-width: 40em;
    }
  }
  &.item-count-2 {
    @include breakpoint('medium') {
      flex-wrap: nowrap;
      @include grid-layout(2, '.panels-item', $global-margin);
    }
  }
  &.item-count-3 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.panels-item', $global-margin);
    }
    @include breakpoint('large') {
      flex-wrap: nowrap;
      @include grid-layout(3, '.panels-item', $global-margin);
    }
  }
  &.item-count-4,
  &.item-count-8 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.panels-item', $global-margin);
    }
    @include breakpoint('large') {
      flex-wrap: nowrap;
      @include grid-layout(4, '.panels-item', $global-margin);
    }
  }
  &.item-count-5 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      .panels-item:nth-of-type(n+3) {
        -webkit-align-content: center;
      }
    }
  }
  &.item-count-6,
  &.item-count-9 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.panels-item', $global-margin);
    }
    @include breakpoint('large') {
      @include grid-layout(3, '.panels-item', $global-margin);
    }
  }
  &-item {
    display: flex;
    width: 100%;
        max-width: 21em;
      @include breakpoint('large') {
        max-width: 25em;
        }
    }
}

//if featured news panel
.panels-item.featured {
  width: 100%;
  @include breakpoint('medium') {
    width: 66%;
    float: left;
    min-width: 44em;
    .panel {
      flex-direction: row;
      width: 100%;
    }
    .panel-image {
      min-height:17em;
      width:50%;
    }
    .panel-content {
      width: 50%;
    }
  }
}
