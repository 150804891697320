.short-header {
  position: relative;
  height: 35vh; // Unihall height
  min-height: 20rem; // for iOS and safari
  width: 100%;
  background: rgba(49, 50, 51, 1);
  @include breakpoint(medium) {
    min-height: 25rem;
    overflow: hidden;
  }
  &-bg {
    &-mobile {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include breakpoint(medium) {
        display: none;
      }
    }
    &-desktop {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include breakpoint(medium) {
        display: block;
        min-height: 25rem;
      }
    }
  }
    &-category {
      font-weight: 600;
      font-size: 1.3rem;
      color: $white;
      max-width:8em;
      margin: 0 auto;
      margin-bottom: $global-margin/4;
      letter-spacing: $header-letterspacing*2;
      padding: $global-padding/4 $global-padding/2;
  }
  &-sub-title {
    @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.4375rem);
    font-family: $header-font-family; // override for Foundation Layer
    line-height: 1.5;
    font-weight: 400;
    max-width: 25em;
    margin: 0 auto;
    letter-spacing: $header-letterspacing*2;
    text-transform: none;
    margin-bottom: $global-margin/1.5;
    text-shadow: none;
    .text-light & {
      color: $black;
    }
    .short-header-icon {
      margin-right: .5rem;
    }
  }
  &-title {
     color: $white;
     font-family: $header-font-family; // override for Foundation Layer
     @include fluid-type('font-size', 40rem, 80rem, 1.728rem, 3.157rem);
    line-height: 1.1;
    margin-bottom: .5em;
    .text-light & {
      color: $primary-color;
    }
    &s {
      &.text-light {
        text-shadow: 0 0 1rem rgba(255,255,255,.5);
      }
      text-shadow: 0 0 1rem rgba(0,0,0,.5);
      position: absolute;
      margin-top: 3.5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      text-align: center;
      width: 80%;
      @include breakpoint(medium) {
        width: 60%;
        margin-top: 4.5rem;
      }
    }
  }
}
