/*
  ------ Overrides for the navigation and tile-row module. ------
  TODO: Move into individual source files.
*/

@media print, screen and (min-width: 40em) {
  .push-nav .nav-list {
    max-height: 24em;
  }
  .push-nav .nav {
    display: block;
  }
  .break-column {
    display: block;
    break-before: column;
  }
  .avoid-break {
    break-inside: avoid;
  }
}
@media print, screen and (max-width: 1119px) {
  .tile-row-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 100%;
  }
  .tile-row-module .tile-row-list .tile-row-list-item:last-child {
    margin-right: 0;
  }
  .tile-row-module .tile-row-list .tile-row-list-item:first-child {
    margin-left: 0;
  }
  .tile-row-module .tile-row-list .tile-row-list-item {
    width: 50%;
    min-height: 22.25rem;
  }
}
@media print, screen and (max-width: 800px) {
  .tile-row-module .tile-row-list .tile-row-list-item {
    width: 50%;
    min-height: 16.25rem;
  }
}
@media print, screen and (max-width: 640px) {
  .tile-row-module .tile-row-list .tile-row-list-item {
    width: 75%;
    min-height: 20.25rem;
  }
}
@media print, screen and (max-width: 500px) {
  .tile-row-module .tile-row-list .tile-row-list-item {
    width: 100%;
    min-height: 17.25rem;
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .tile-row-module .tile-row-list .tile-row-list-item:last-child {
    margin-right: 1.5em;
  }
  .tile-row-module .tile-row-list .tile-row-list-item:first-child {
    margin-left: 1.5em;
  }
}
@media print, screen and (max-width: 450px) {
  .tile-row-module .tile-row-list .tile-row-list-item {
    min-height: 15.25rem;
  }
}
@media print, screen and (max-width: 375px) {
  .tile-row-module .tile-row-list .tile-row-list-item {
    min-height: 14.25rem;
  }
}

.tile-row-module .tile-row-scroll {
  overflow-x: hidden;
}
