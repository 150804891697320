// Unihall search styling - overwriting FS
.future-students-search {
  margin: 0 auto;
  margin-top: -1rem;
  max-width: 65rem;
     input[type=search] {
      margin: 0 auto;
      width: 80%;
      border: 1px solid lightgray;
      border-bottom: 3px solid lightgray;
      box-shadow: none;
      height: 4rem;
      &:focus {
        border: 1px solid $primary-color;
        border-bottom: 3px solid $primary-color;
        box-shadow: none;
      }
    }
    button[type=submit] {
      @include fluid-type('font-size', 40rem, 80rem, 1.1875rem, 1.3125rem);
      background: $primary-color;
      color: $darkest-gray;
      letter-spacing: $header-letterspacing;
      height: 4rem;
      width: 20%;
      font-weight: 600;
      &:hover {
        border: 3px solid $primary-color;
        color: $darkest-gray;
        background: $white;
      }
      &:focus {
        background: $primary-color;
        color: $darkest-gray;
      }
      i {
        padding-right:1rem;
        font-size: 1rem;
      }
    }
}
.site-search-results-content-col {
  margin: 0 auto;
  width:100%;
}
