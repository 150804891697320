@import 'includes';

.panels-horizontal {
  @include clearfix;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
.panels-item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
  max-width: none;
  @include breakpoint('large') {
    flex-wrap: wrap;
    width:50%;
    }
  }
}

//New events panel styling

.events-panels-module {
  .results-list {
    @include clearfix;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    @include breakpoint('large') {
      flex-wrap: nowrap;
    }
    .result-item{
      width: 100%;
      min-width: 28em;
      @include breakpoint('large') {
      margin: .5rem;
      width: 50%;
      min-width: 28em;
      img {
        min-width: 350px;
        @include breakpoint('large') {
        min-width: auto;
        min-height: 220px;
        }
      }
    }
    .result-item-img {
      height: auto;
      }
    }
  .result-item--event .result-item-content {
    padding-right: 0;
    h4 {
      width: 90%;
      @include breakpoint('large') {
        width: 80%;
      }
    }
  }
  }
 }
