.info-banner {
  &-play-video {
    width: 6rem;
    height: 6rem;
    text-indent: 6rem;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    white-space: nowrap;
    position:absolute;
    top: 35%;
    left: 45%;
    &:hover {
      &:before {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      display: block;
      top: 50%;
      left: 50%;
    }
    &:before {
      border-radius: 50%;
      background: rgba($primary-color, .75);
      width: 4rem;
      height: 4rem;
      transform: translate(-50%, -50%);
      transition: transform .25s cubic-bezier(.23, .54, .64, 1.53);
    }
    &:after {
      width: 0;
      height: 0;
      border: 1rem solid transparent;
      border-left-color: $darkest-gray;
      transform: scale(1, .6) translate(-20%, -80%);
    }
  }
  &-video {
    &-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $white;
      display: none;
      max-height: 62vh;
      &.shown {
        z-index: 1000;
        display: block;
        animation: reveal-video .25s ease forwards;
      }
    }
    position: absolute;
    top: 0%;
    transform: translateY(-50%);
    left: -50%;
    width: 200%;
    margin: 0;
    padding-bottom: 56.25%;
    height: 100%;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @include breakpoint(medium) {
      top: 0;
      transform: none;
    }
  }
}

.info-banner,
.info-banner-carousel {
  @include breakpoint(medium) {
    position: relative;
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  &-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    .info-banner {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity .25s ease;
      &.hidden {
        opacity: 0;
        z-index: -1;
        .no-js & {
          position: relative;
          top: auto;
          left: auto;
          z-index: auto;
          opacity: 1;
        }
      }
    }
  }
  &-item,
  &-item-content {
    padding: $global-padding*2.5;
    width: 100%;
    height: 100%;
    @include breakpoint(medium) {
      float: left;
      width: 40%;
  }
}
  &-item-content {
    @include breakpoint(medium) {
      position:absolute;
      overflow:auto;
      left: 0;
      padding: $global-padding*3;
      }
    @include breakpoint(xlarge) {
      padding: $global-padding*4 $global-padding*3 0 $global-padding*3;
      }
}
  &-img {
    display: none;
    @include breakpoint(medium) {
      display: block;
      padding: 0;
      width:100%;
      max-height: 700px;
    }
  }
  &-container {
    display: none;
    @include breakpoint(medium) {
      display: block;
      padding: 0;
      width: 60%;
      float:right;
      position:relative;
      overflow: hidden;
      max-height:62vh;
    }
  }
  &-btn {
    //@include primary-button-hollow(true);
    width: 100%;
    margin-top: $global-margin*2;
    @include breakpoint(large) {
      width: 60%;
    }
  }
  &-title {
    font-family: $header-font-family;
    color: inherit;
    @include fluid-type('font-size', 40rem, 80rem, 1.44rem, 2.369rem);
    @include breakpoint(medium) {
      line-height: $global-lineheight*.7;
    }
  }
   &-text {
        margin-top: .6rem;
    }
  &-link-list {
    list-style-type: none;
    margin: 0;
    line-height: $global-lineheight;
    li {
      margin: .5rem 0;
    }
  }
  &-link-item {
    @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
    @include link-chevron ();
    color: $darkest-gray;
    &:hover {
      color: $primary-color;
    }
  }
  &-link-text {
    @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.1rem);
    @include link-chevron ();
    }
}

.module-container.full-bleed {
        .info-banner-item-content {
            @include breakpoint(xlarge) {
          padding: $global-padding*5 $global-padding*3 0 $global-padding*3;
      }
  }
}

.key-info-banner-item-content {
blockquote {
    &:before {
        @include fluid-type('font-size', 40rem, 80rem, 6rem, 8rem);
            }
    &:after {
        @include fluid-type('font-size', 40rem, 80rem, 6rem, 8rem);
        }
    }
}

.alt {
  .info-banner-item {
    @include breakpoint(medium) {
    float: right;
  }
  }
  .info-banner-item-content {
    @include breakpoint(medium) {
    left: 60%;
  }
  }
  .info-banner-container {
    @include breakpoint(medium) {
    float: left;
  }
  }
}
