.overlapping {
  &-image {
  position: relative;
  max-width: 18em;
  //height: 320px; /* For IE */
  //height: 5em;
  margin: 0 auto;
  z-index: 2; /* higher than the content layer */
  @include breakpoint('medium') {
    margin: 0 auto;
    //min-height: 20rem; /* For IE */
    //height: 7em;
    max-width: 35rem;
  }
  img {
      border-radius: 5px;
      display:block;
      opacity: 1 !important;
      width: 100%;
  }
  &-content {
    //flex-basis: 50%;
    padding: 2rem 2rem 1rem 2rem;
    margin: 0 auto;
    max-width: 50rem;
    z-index: 1;
    @include breakpoint('medium') {
      padding: 0rem 4rem 1rem 4rem;
    }
  }
  &-module {
    display: block;
    //flex-wrap:wrap;
    //flex-direction:column;
    width: 100%;
    margin-bottom: -4rem;
    &:after {
          clear:both;
    }
    @include breakpoint('medium') {
      margin-bottom: -10rem;
    }
   }
 }
}

// Module wrapper settings
.top-padding {
  .overlapping-image {
       height: 5em;
     @include breakpoint('medium') {
       height: 5em;
   }
  }
}
