.header {
    overflow: hidden;
    padding-top: 1rem;
    width: 100%;
    max-width: $global-width;
    margin: 0 auto;
    left: 0;
    right: 0;
    @include fluid-type('font-size', 40rem, 80rem, .6rem, 1rem);
    @include grid-row();
    @include breakpoint(medium) {
      padding-top: 1.65rem;
    }
    &-logo {
      float: left;
      width: 20em;
	  height: 6em;
      display: inline-block;
      background:url(/assets/img/unihall_logo.png) no-repeat 0 0;
      background-size: contain;
      margin-left: .5rem;
      @include breakpoint(medium) {
        margin-left: 1rem;
		width: 18em;
      }
      @include hide-text;
    }
    &-nav {
      @include grid-row();
      display: inline-block;
      float: right;
      font-size: .6rem;
      padding-top: 0;
      @include breakpoint(medium) {
        font-size: .8rem;
        padding-top: .5rem;
      }
      &-item {
        float: left;
        position: relative;
        display: block;
        color:$white;
        text-shadow: none;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        padding: .5rem .5rem;
        transition: background .25s ease;
        font-weight: 600;
        @include breakpoint(medium) {
          padding: .5rem 1rem;
        }
        .fa {
          font-size: 2em;
          font-weight: normal;
          display: block;
          margin: 0 auto .3rem;
        }
        &:focus,
        &:hover {
          background: $primary-color;
          color: $black;
          text-decoration: none;
        }
      }
    }

  &-container {
    z-index: 1000;
    left: 0;
    right: 0;
    position: absolute;
    background: linear-gradient(to bottom, rgba($black, .4) 0%, rgba($black, 0) 100%);
    transition: background .25s ease;
    &.dark-gradient {
      background: linear-gradient(to bottom, rgba($black, .8) 0%, rgba($black, 0) 100%);
     }
	 &.header-fixed {
      position: fixed;
      top: 0!important;
      left: 0;
      width: 100%;
      background: $primary-color-light;
      animation: show-fixed-header .25s ease;
      z-index: 1000;
	  padding-bottom:1rem;
	  box-shadow: 0 14px 14px -14px rgba(0, 0, 0, 0.1);
	  
	  .header-nav {
		padding-top:0;
	  }
	  
	  .header {
		padding-top:1rem;
	  }
	  
	  .header-nav-item {
		color: $black;
		
		&:hover {
			color: $white;
			background: none;
		}
	  }
	  .header-logo {
		background:url(/assets/img/unihall_logo.png) no-repeat 0 0;
		background-size: contain;
		width: 17em;
		height: 4em;
		   @include breakpoint(medium) {
		width: 18em;
      }
	  }
    }
  }
}


@keyframes show-fixed-header {
  from {
    transform: translateY(-100%);
  }
}

