﻿// Default SlickVariables
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $white;
$slick-dot-color-dark: $dark-gray;
$slick-dot-color-active: $white;
$slick-dot-color-dark-active: $darker-gray;
$slick-dot-size: 15px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.75;


.slick-slider, .slick-list, .slick-track, .slick-active, .slick-slide {
 height: 100% !important;
}
.slick-active, .slick-slide {
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
}

/* Dots */

.slick-dotted.slick-slider {
    margin: 0;
}

.slick-dots {
    position: absolute;
    bottom: 15px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 25px;
        width: 25px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '\00a0\00a0\00a0\00a0\f10c';
                font-family: fontawesome;
                width: 20px;
                height: 20px;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $white;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
            content: '\00a0\00a0\00a0\00a0\f111';
            font-family: fontawesome;
        }
    }
}


// Unihall slick styling
.slick-arrow {
  transform: none !important;
  border: none!important;
  z-index: 5;
  background: none;
}
.slick-prev {
  margin-left: 0;
}
.slick-next {
  margin-right: 0;
  right: 0 !important;
}
.slick-prev:before, .slick-next:before {
 color: white;
 background: none;
 padding: 1rem;
 display: block;
}
.slick-next,
.NextArrow {
  top: 50%;
  border: none;
  margin-top: -22.5px;
  z-index: 5;
  border: 0;
  background: transparent;
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 2rem;
  cursor: pointer;
  margin-right: -1rem;
  &:before {
    content: '\f105';
    position: absolute;
    background: rgba(0,0,0,.50);
    color: $white;
    top: 0;
    right: 1rem;
    border: none;
    font-family: fontawesome;
    font-size: 2rem;
    line-height: 20px;
    text-align: center;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @include breakpoint(medium) {
    margin-right: auto;
  }
}
.slick-prev,
.PrevArrow {
  top: 50%;
  border: none;
  margin-top: -22.5px;
  z-index: 5;
  border: 0;
  background: transparent;
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 2rem;
  cursor: pointer;
  margin-left: -1rem;
  &:before {
    content: '\f104';
    position: absolute;
    border: none;
    background: rgba(0,0,0,.50);
    color: $white;
    top: 0;
    right: 1rem;
    font-family: fontawesome;
    font-size: 2rem;
    line-height: 20px;
    text-align: center;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @include breakpoint(medium) {
    margin-left: auto;
  }
}
