﻿/// <reference path="../app" />

.header-snippets {
    @include clearfix;
        @include grid-layout(3, '.header-snippet', $global-margin);
    &.item-count-2 {
        @include breakpoint('medium') {
            @include grid-layout(2, '.header-snippet', $global-margin);
        }
    }
    &.item-count-4 {
        @include breakpoint('medium') {
            @include grid-layout(2, '.header-snippet', $global-margin*1);
        }
        @include breakpoint('large') {
            @include grid-layout(4, '.header-snippet', $global-margin*2);

        }
    }
    &.item-count-5 {
      .header-snippet:nth-of-type(n+3) {
        -webkit-align-content: center;
    }
        @include breakpoint('medium') {
            @include grid-layout(5, '.header-snippet', $global-margin*1);
        }
    }
}

.header-snippet {
    @include clearfix;
    @include breakpoint('medium') {
        text-align: center;
    }
    &-title {
        @include fluid-type('font-size', 40rem, 80rem, .6rem, .75rem);
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: 1.5px;
        text-shadow: 0 1px 0 black;
        &:empty {
            margin: 0;
        }
    }
    &-module {
      margin-top: -5em;
      z-index: 2;
      color: white;
      position: absolute;
      max-width: 100%;
      width: 100%;
      left: 0%;
      padding: 1rem;
      background: darken($darkest-gray, 10%);
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      @include breakpoint('medium') {
        margin-top: -9em;
        max-width: 50em;
       left: 1%;
      }
      @include breakpoint('large') {
       left: 20%;
      padding: 1.5rem 2rem;
      }

    }
}


.header-snippet-icon {
    display: block;
    margin: 0 1rem 0 0;
    color: $lighter-gray;
    float: left;
    //background: lighten($black, .2%);
    //border-radius: 1%;
    //padding: .5rem;
    @include fluid-type('font-size', 40rem, 80rem, 1.2rem, 1.5rem);
    text-shadow: 0 1px 0 black;
    @include breakpoint('medium') {
        display: inline-block;
        margin: 0 0 1rem 0;
        float: none;
        width: auto;
    }
}
