//Key info banner styles
.alt {
  .key-info-banner-item {
    float: right;
    padding: $global-padding*2;
    @include breakpoint(medium) {
      padding: $global-padding*4;
    }
    @include breakpoint(large) {
    padding: $global-padding*2.5 $global-padding*5 $global-padding*2.5 $global-padding;
    }
  }
}

.key-info-banner {
    overflow: hidden;
    min-height:380px;
  @include breakpoint(large) {
    position: relative;
    min-height:640px;
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  &.alt-bg {
    background: $light-gray;
  }
   &.dark-bg,
    .dark-photo-bg {
        color: $white;
        cite {
        color: $white;
        }
    }
   &-container {
       max-width: 1320px;
       margin: 0 auto;
   }
  &-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    .key-info-banner {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity .25s ease;
      &.hidden {
        opacity: 0;
        z-index: -1;
        .no-js & {
          position: relative;
          top: auto;
          left: auto;
          z-index: auto;
          opacity: 1;
        }
      }
    }
  }
  &-item {
    padding: $global-padding*2;
    width: 100%;
     @include breakpoint(medium) {
      padding: $global-padding*4;
    }
    @include breakpoint(large) {
      float: left;
      padding: $global-padding*2.5 0 $global-padding*5 $global-padding*2.5;
      width: 50%;
      height: 100%;
      &-content {
          @include breakpoint(large) {
          margin-top:6rem;
          }
      }
    }
  }
  &-img {
    display: none;
    bottom:0;
    @include breakpoint(large) {
      display: block;
      padding: 0;
      width: 50%;
    }
    &-content {
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      max-width: 580px;
      bottom:0;
    }
  }
    &-play-btn {
    width: 80%;
    margin:0 auto;
    color: $darkest-gray;
    border-color: $primary-color;
    background: $primary-color;
    @include breakpoint(large) {
      width: 50%;
      padding: .75em .5em;
    }
    &:before {
      @include fa-icon;
      content: $fa-var-play;
      margin-right:$global-padding/2;
      font-size: .9rem;
    }
    &:hover,
    &:focus {
      color: $darkest-gray;
      background: transparent;
    }
  }
    &-btn {
    width: 80%;
    margin:0 auto;
    @include breakpoint(large) {
      width: 50%;
      padding: .75em .5em;
    }
  }
  &-title {
    color: $anchor-color;
    font-family: $header-font-family;
    @include fluid-type('font-size', 40rem, 80rem, 1.44rem, 2.369rem);
    @include breakpoint(large) {
      line-height: $global-lineheight*.7;
    }
    &:hover {
      color: $primary-color;
      text-decoration: none;
      border-bottom: 2px solid;
    }
  }
   &-text {
        margin-top: .6rem;
    }
  &-link-list {
    list-style-type: none;
    margin: 0;
    line-height: $global-lineheight;
    li {
      margin: .5rem 0;
    }
  }
  &-link-item {
    color: $anchor-color;
    @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
    @include link-chevron ();
  }
  &-link-text {
    @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.1rem);
    @include link-chevron ();
    }
}

.key-info-banner-item-content {
blockquote {
    &:before {
        @include fluid-type('font-size', 40rem, 80rem, 6rem, 6rem);
            }
    &:after {
        @include fluid-type('font-size', 40rem, 80rem, 6rem, 6rem);
        }
    }
}
