﻿/// <reference path="../app" />

.info-snippets {
    @include clearfix;
    @include breakpoint('medium') {
        @include grid-layout(3, '.info-snippet', $global-margin*2);
    }
    &.item-count-2 {
        @include breakpoint('medium') {
            img {
                max-width: 70%;
            }
            @include grid-layout(2, '.info-snippet', $global-margin);
        }
    }
    &.item-count-4 {
        @include breakpoint('medium') {
            @include grid-layout(2, '.info-snippet', $global-margin*1);
            .info-snippet:nth-of-type(2) {
                margin-bottom: 3rem;
            }
        }
        @include breakpoint('large') {
            @include grid-layout(4, '.info-snippet', $global-margin*2);

        }
    }
}

.info-snippet {
    @include clearfix;
    margin-bottom: 2.2rem;
    @include breakpoint('medium') {
        text-align: center;
        margin-bottom: 1rem;
    }
    ._title { // override for Foundation Layer
        @include fluid-type('font-size', 40rem, 80rem, 1.2rem, 1.375rem);
        font-weight: 600;
        color: $darkest-gray;
        font-family: $header-font-family; // override for Foundation Layer
        &:empty {
            margin: 0;
        }
    }
    &-details {
        overflow: hidden;
    }
    &-description {
        margin-bottom: .6rem;
        line-height: 1.4;
        font-size: 1rem;
    }
    &-module {
      padding: 1rem;
    }
}

.info-snippet-icon {
    @include fluid-type('font-size', 40rem, 80rem, 2rem, 2.5rem);
    &-svg {
        font-size: 2.5em;
        @include breakpoint('medium') {
          font-size: 3.5em;
        }
        .svg-icon {
          width: 1em;
          height: 1em;
        }
          @include breakpoint('medium') {
          width: 1.3em;
          height: 1.3em;
        }
    }
    &-img {
        @include breakpoint('medium') {
            max-width: 80%;
        }
    }
}

.info-snippet-icon-img,
.info-snippet-icon-svg,
.info-snippet-icon {
    display: block;
    margin: 0 1rem 0 0;
    width: 3.5rem;
    color: $dark-gray;
    float: left;
    @include breakpoint('medium') {
        display: inline-block;
        margin: 0 0 1rem 0;
        float: none;
        width: auto;
    }
}


.info-snippet-link,
.info-snippet-list li {
            font-size: inherit;
            font-weight: 600;
            color: $darkest-gray;
            &:after {
            @include fa-icon();
            content: $fa-var-angle-right;
            }
            &:hover {
              color: $primary-color;
              text-decoration: none;
             }
            }


.info-snippet-list {
    list-style-type: none;
    margin: 0;
    line-height: $global-lineheight;
}


//Module colour variations
.dark-bg {
  .info-snippet-icon {
    color: $white;
  }
  .info-snippet-link {
    color: $primary-color !important;
    &:hover {
      color: $primary-color-light;
      text-decoration: none;
     }
  }
}

.pink-bg,
.purple-bg,
.blue-bg {
  .info-snippet-link,
  h3 {
    color: $white;
  }
}
.green-bg,
.yellow-bg {
  .info-snippet-link {
    color: $black;
  }
}
