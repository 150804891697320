.utility-links {
  display: none;
  font-size: .8rem;
  margin: 0;
  @include breakpoint(medium) {
    display: block;
    width: 100%;
    background: $primary-color-light;
    height: 2.5em;
    a {
      line-height: 1.5;
	  font-size: .9rem;
	  font-weight: 600;	  
      transition: background .1s linear;
      text-decoration: none;
      float: right;
      color: $black;
      padding-right: 1rem;
	  padding-top: .25rem;
      position: relative;
      &:hover {
        color: #000;
		text-decoration: underline;
      }
    }
  }
  &-container {
	max-width: 75rem;
	margin: 0 auto;
  }
}