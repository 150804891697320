﻿/// <reference path="../app" />
.cta-row-list {
  @include clearfix;
  @include breakpoint('medium') {
    flex-wrap: nowrap;
    @include grid-layout(3, '.cta-row-list-item', $global-margin);
  }
  &.item-count-1 {
    @include breakpoint('medium') {
      flex-wrap: nowrap;
      @include grid-layout(1, '.cta-row-list-item', $global-margin);
      .cta-row-link {
        width: 30%;}
    }
  }
  &.item-count-2 {
    @include breakpoint('medium') {
      flex-wrap: nowrap;
      @include grid-layout(2, '.cta-row-list-item', $global-margin);
    }
  }
  &.item-count-4 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.cta-row-list-item', $global-margin);
    }
    @include breakpoint('large') {
      flex-wrap: nowrap;
      @include grid-layout(4, '.cta-row-list-item', $global-margin);
    }
  }
  &.item-count-5 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      .cta-row-list-item:nth-of-type(n+3) {
        -webkit-align-content: center;
      }
    }
  }
}

.cta-row-module {
  position:relative;
  width: 100%;
}

.cta-row {
  &-bg {
    &-mobile {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include breakpoint(medium) {
        display: none;
      }
    }
    &-desktop {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include breakpoint(medium) {
        display: block;
        min-height: 25rem;
      }
    }
  }
  &-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  &-item {
    display: flex;
    flex-grow: 0;
    width: 100%;
    padding-bottom: 1rem;
    flex-shrink: 1;
    flex-basis: auto;

    @include breakpoint('medium') {
      width: auto;
    }
   }
  }
  &-link {
    margin: .5rem 0;
    min-height: 100%;
    width: 100%;
    display: flex!important;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: inherit;
    border-color: inherit;
    span {
      flex: 0 1 auto;
    }
    .item-count-1 & {
      @include breakpoint('medium') {
        width: 20%;
        margin: .5rem auto;
        padding: .8em 1em;
      }
    }
    &::after {
      content: none;
    }
  }
}

.cta-row-link-icon {
  display: block;
}
