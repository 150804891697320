.results {
  padding-top: 1em;
  max-width: 65rem;
  margin: 0 auto;
  // border-top: 1px solid $medium-gray;
  //
  &.border-top {
        border-top: 1px solid $medium-gray;
      }
  &-criteria {
    @include breakpoint(medium) {
      margin-top: 0;
    }

    + .results-header {
      margin-top: 2rem !important;
    }


    &-item {
      font-size: 0.75em;
      background: $light-gray;
      display: inline-block;
      padding: 0.3rem .6rem;
      border-radius: 0.2rem;
      margin: 0 0.5em 0.5em 0;

      button {
        position: relative;
        margin-left: 0.5em;
        width: 0.7em;
        height: 0.7em;
        vertical-align: middle;

        &:after,
        &:before {
          background: $black;
          content: '';
          height: 1px;
          width: 0.989em;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          display: block;
        }

        &:after {
          transform: translateX(-50%) rotate(-45deg);
        }

        &:focus,
        &:hover {
          &:after,
          &:before {
            height: 2px;
          }
        }
      }
    }
  }

  &-header {
    @include clearfix;
    border-bottom: 3px solid $light-gray;
    // @include breakpoint(medium) {
    //   margin-top: -(2*$global-padding);
    // }
    // @include breakpoint(large) {
    //   margin-top: -(4*$global-padding);
    // }
    margin: 3rem auto;

    h4 {
      @include fluid-type('font-size', 40rem, 80rem, 1.2rem, 1.777rem);
      margin-bottom: 0;
      color: $darker-gray;
      font-family: $body-font-family;
      font-weight: 700;
    }

    p {
      @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.1rem);
      font-weight: 300;
      color: $darker-gray;
    }

    &-summary {
      width: 50%;
      float: left;
    }

    &-sort {
      width: 50%;
      float: right;
      text-align: right;

      &--research {
        width: 20%;
        @include breakpoint(medium) {
          width: 10%;
        }
      }

      .radio-select {
        margin-right: -.7rem;
      }
    }
    .results-header-sort--program {
      padding: 0 1rem;
    }
  }

  &-list {
    padding-bottom: 2.4rem;
    border-bottom: none;
    margin: 0 auto;
    max-width: 65rem;
    &--myProgram {
      h3 {
        margin-bottom:0px;
      }
    }
  }

  &-action {
    text-align: center;
    padding: 2.4rem 0;

    &-load-more {
      background: $white;
      color: $darkest-gray;
      font-weight: 700;
      line-height: 1;
      padding: 1rem 2.5rem;
      margin-right: 0.5rem;
      transition: background 0.25s ease;
      border: 3px solid $primary-color-light;
      letter-spacing: 0.8px;
      transition: color 0.25s ease;

      &:focus,
      &:hover {
        background: $primary-color-light;
        border: 3px solid $primary-color-light;
        color: $darkest-gray;
      }

      &[disabled] {
        background: $medium-gray;

        &:hover {
          background: $medium-gray;
        }
      }
    }

    &-refine {
      font-weight: 700;
      color: $darkest-gray;

      padding: 0.8rem 2.2rem;
      margin-left: 0.5rem;
      transition: color 0.25s ease;

      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
  }

  .accordion-title {
    background: #f5f8fc;
    font-family: $header-font-family;
    font-weight: 400;
    font-size: 1.2em;

    &:hover {
      background: $light-gray;
    }
  }

  .accordion-item {
    border: none;
    margin-bottom: 0.2rem;

    &.is-active {
      .accordion-title,
      .results-count {
        background: none;
      }
    }
  }

  &-count {
    background: #e2edfa;
    font-family: $body-font-family;
    float: right;
    font-size: 0.6em;
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    text-align: center;
    padding-top: 0.3em;
  }

  &.results--research {
    border-top: none;
  }

  &.results--event {
    border-top: none;
  }
  &--site-search {

  }
}

.results--agent,
.results--research {
  width: 100%;
  margin-top: 0.5em;
  @include breakpoint(medium) {
    width: auto;
  }
}
