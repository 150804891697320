$easing: cubic-bezier(0.23, 1, 0.32, 1);

.u-flex {
    display: flex;
}

.u-flex-center {
    align-items: center;
}

.u-flex-end {
    align-items: flex-end;

    @include breakpoint(medium only) {
        align-items: flex-start;

        img{
            transform: translateY(12px);
        }

    }

    @include breakpoint(large only) {
        align-items: flex-start;

        img{
            transform: translateY(12px);
        }

    }

}

.u-text-center {
    text-align: center;
}

.u-list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

.u-invisible{
    visibility: hidden;
}

.u-tablet-block{

    @include breakpoint(medium only) {
        display: block !important;
    }

    @include breakpoint(large only) {
        display: block !important;
    }
    
}

.u-max-width{

    .module-wrapper{

        @include breakpoint(medium down) {
            max-width: 600px;
            margin: 0 auto;
        }    

    }

}

.u-nowrap{
    white-space: nowrap;
}

.short-header {

    &.-full-height {
        height: 100%;

        h1 {
            margin-bottom: 1em;
        }

        .short-header-titles {
            margin-top: 0;

            @include breakpoint(small only) {
                width: calc(100% - 40px);
            }

        }

        .btn-primary.large{

            @include breakpoint(small only) {
                font-size: 16px;
            }

        }

    }

}

.html--col{
    overflow: hidden;
    height: 100%;

    .module-wrapper.no-padding, .module-container.full-bleed{
        height: 100%;
    }

    .tooltip{
        font-size: 14px;
        line-height: 140%;
        padding: 10px 16px 12px;
        border-radius: 4px;
        background-color: $darkest-gray;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            li{
                position: relative;
                padding-left: 20px;

                &:not(:first-child){
                    margin-top: 8px;
                }

                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 12px;
                    height: 9px;
                    background-image: url('/assets/img/cost-of-living/icon-tick--green.svg');
                    background-size: cover;
                }

            }

        }

        &:before{
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color:$darkest-gray transparent transparent transparent;
        }

    }

}

.colWidget{

    &.is-overlay{

        @include breakpoint(medium down) {

            &:before{
                content: '';
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(#000, .8);
                width: 100%;
                height: 100%;
                z-index: 2;
            }

        }

    }

    .has-tip{
        border: 0;
        cursor: pointer;
        font-weight: normal;

        &:after{
            display: none;
        }
        
    }

    h2,
    h3 {
        font-weight: 700;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 18px;
        color: #000;
        line-height: 110%;

        @include breakpoint(medium) {
            font-size: 30px;
            margin-bottom: 24px;
        }

        @include breakpoint(xxlarge) {
            font-size: 36px;
        }

    }

    p {
        font-weight: 300;
    }

    .btn {
        display: inline-block;
        border-radius: 4px;
        padding: 7px 18px 10px;
        line-height: inherit;

        @include breakpoint(medium) {
            padding-left: 24px;
            padding-right: 24px;
        }

        &-primary {
            border: 1px solid $primary-color-light;
            color: #fff;

            &:hover, &:focus{
                color: $primary-color-light !important;
                background: $black !important;
            }

        }

        &-secondary-hollow {
            border: 1px solid $darkest-gray;
        }

        .fa{
            margin-right: 0;
            transform: translateY(1px);

            &.fa-angle-double-left{
                margin-right: 5px;
            }

            &.fa-angle-double-right{
                margin-left: 5px;
            }

        }

    }
        
    .short-header-title{

        @include breakpoint(small only) {
            font-size: 24x;
            line-height: 30px;
        }

    }

    .tick-list {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;
    
        @include breakpoint(xxlarge) {
            font-size: 16px;
        }
    
        li {
            position: relative;
            padding-left: 28px;
            min-height: 20px;
            margin-bottom: 8px;
            font-weight: 600;

            @include breakpoint(large only) {
                font-size: 13px;
            }
    
            @include breakpoint(xxlarge) {
                padding-left: 34px;
                min-height: 24px;
                margin-bottom: 12px;
            }

            &:not(.no-style){

                &:before {
                    background-image: url('/assets/img/cost-of-living/icon-tick.svg');
                    background-size: cover;
                    width: 20px;
                    height: 20px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
        
                    @include breakpoint(xxlarge) {
                        width: 24px;
                        height: 24px;
                    }
        
                }

            }
    
            &.-conv {
    
                color: #545B94;
    
                &:before {
                    background-image: url('/assets/img/cost-of-living/icon-convenience.svg') !important;
                }
    
                button {
                    text-decoration: underline;
                    color: #545B94;
                    font-family: $body-font-family;
    
                    &:hover {
                        text-decoration: none;
                    }
    
                }
    
            }
    
        }
        &.-cross {
    
            li:before {
                
                background-image: url('/assets/img/cost-of-living/icon-cross.svg');
            }
        }

        + br{

            @include breakpoint(small only) {
                display: none;

                + h4{
                    margin-top: 10px;
                }
            }

        }
    
    }
    
    .greybox {
        background: $darkest-gray;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        padding: 15px 35px;
        margin: 25px 0;
    
        sup {
            top: -1.2em;
            font-size: 21px;
            margin-right: 5px;
    
            @include breakpoint(small only) {
                font-size: 13px;
            }
    
        }
    
        strong {
            font-size: 39px;
            line-height: 100%;
            display: block;
            margin-bottom: 10px;
    
            @include breakpoint(medium) {
                font-size: 60px;
            }
    
        }
    
        p {
            font-size: 12px;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 120%;
    
            @include breakpoint(medium) {
                font-size: 14px;
            }
    
        }
    
    }

    .slide {

        @include breakpoint(large) {
            opacity: .2;
            transform: translateX(-18%);
            transition: all 1s $easing;
    
            &.active{
                opacity: 1;
                transform: translateX(0);
            }
    
        }
    
        @include breakpoint(xxlarge) {
            transform: translateX(-24%);
        }
    
        &.is-active{

            @include breakpoint(medium) {

                .slide-content__full{
                    opacity: 1;
                }
        
                .slide-content__img{
            
                    img, svg{

                        @include breakpoint(medium only) {
                            width: 150px;
                        }

                        @include breakpoint(large) {
                            height: 12vh;
                            width: auto !important;
                        }
            
                        @include breakpoint(xxlarge) {
                            height: 16vh;  
                            margin-top: -20px;
                        }

                        .is-ie &{
                            width: 150px !important;
                        }
                            
                        &.svg1{
                            transform: translateX(-40px);

                            @include breakpoint(large) {
                                transform: translateX(-26px)
                            }

                            @include breakpoint(xxlarge) {
                                transform: translateX(-46px);
                            }

                        }

                        &.svg2{
                            transform: translateX(-18px);
                        }

                        &.svg3{
                            transform: translateX(-50px);

                            @include breakpoint(xxlarge) {
                                transform: translateX(-60px)
                            }

                        }

                        &.svg4{
                            transform: translateX(-25px);

                            @include breakpoint(xxlarge) {
                                transform: translateX(-35px)
                            }

                        }

                        &.svg7{
                            transform: translateX(-40px);

                            @include breakpoint(xxlarge) {
                                transform: translateX(-60px);
                            }


                        }

                        &.svg8{
                            transform: translateX(-20px);
                        }
            
                    }
            
                }

            }
        
        }

        .svgBackground{

            @include breakpoint(medium) {
                opacity: 1;
                transition: opacity  .7s linear;
            
                &.is-active{
                    opacity: 0;
                }

            }
        
        }
    
    }

    .slide-content {
        padding: 20px;
        position: relative;
    
        @include breakpoint(large) {
            padding: 30px 0 50px;
            margin-left: 30px;
            max-width: calc(100% - 370px);
        }

        @include breakpoint(large only) {
            padding-top: 20px !important;
        }
    
        @include breakpoint(xxlarge only) {
            @include interpolate('margin-left', $xxlarge, 50px, $maxWidth, 180px, 'ease');
            @include interpolate('max-width', $xxlarge, 865px, $maxWidth, 1030px, 'ease');
            padding-top: 50px;
        }
    
        h2 {
            font-size: 20px;
            margin-top: 0;
    
            @include breakpoint(small only) {
                padding-left: 90px;
                line-height: 110%;
                margin-bottom: 5px;
            }
    
            @include breakpoint(medium) {
                margin-top: 15px;
                font-size: 30px;
            }
    
            @include breakpoint(large) {
                @include interpolate('font-size', $xlarge, 30px, $maxWidth, 45px, 'ease');
            }
    
            @include breakpoint(large only) {
                margin-bottom: 10px;
            }
    
        }
    
        &__full{

            @include breakpoint(medium) {
                opacity: 0;
                transition: opacity .3s $easing;
                transition-delay: .4s;
            }
        
            > p {
                font-size: 13px;
                margin-bottom: 0;
        
                @include breakpoint(small only) {
                    padding-left: 90px;
                }
        
                @include breakpoint(medium) {
                    margin-bottom: 20px;
                    font-size: 14px;
                }

                @include breakpoint(large) {
                    font-size: 16px;
                }
        
                @include breakpoint(xxlarge) {
                    font-size: 18px;
                    margin-bottom: 30px;
                }
        
            }
        
        }

        &__img{

            @include breakpoint(small only) {
                position: absolute;
                top: 20px;
                left: 20px;
            }
        
            img, svg{

                @include breakpoint(small only) {
                    width: 72px;
                    height: auto;
                }
        
                @include breakpoint(medium) {
                    height: auto;
                    max-width: 100%;
                }
        
                @include breakpoint(large) {
                    height: 70vh;
                }
        
            }
        
            img, svg{
                transition: all .5s linear;
            }
        
        }

    }

    .chart-element{

        img{
    
            @include breakpoint(small only) {
                height: 20px;
            }
    
        }
    
        &:not(:first-child), + .tick-list{
            margin-top: 20px;
    
            @include breakpoint(small only) {
                margin-top: 10px;
            }
    
        }
    
    }
    
    .comp-el__title + .chart-element{
        margin-top: 20px;
    }

    .comp-1col {
        padding: 20px;

        @include breakpoint(medium) {
            background: #FFFFFF;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
            border-radius: 4px;
        }
    
        @include breakpoint(xxlarge) {
            padding: 38px;
        }
    
        ul {
            display: flex;
        }
    
        li {
    
            @include breakpoint(medium) {
                flex: 0 0 33.333%;
                max-width: 33.333%;

                &:not(:first-child) {
                    border-left: 1px solid rgba(#000000, .1);
                }

            }
    
            @include breakpoint(small only) {
                display: flex;
            }
    
            &:nth-child(1) {
    
                .comp-1col__item {
                    padding-left: 0;
                }
    
            }
    
            &:nth-child(3) {
    
                .comp-1col__item {
                    padding-right: 0;
                }
    
            }
    
        }
    
        &__item {
            font-size: 14px;
            padding: 0 25px;

            @include breakpoint(small only) {
                height: auto !important;
                min-width: 260px;
                padding-left: 0;
            }
    
            @include breakpoint(xxlarge) {
                padding: 0 35px;
                font-size: 16px;
            }
    
            &:first-child {
                margin-bottom: 20px;
                
                @include breakpoint(xlarge) {
                    margin-bottom: 30px;
                }

            }
    
            img {
                margin-bottom: 10px;

                @include breakpoint(large down) {
                    height: 25px;
                }
    
            }
    
            p {
                font-weight: 400;
                margin: 0;
                font-size: 14px;

                @include breakpoint(small only) {
                    font-size: 13px;
                }

            }
    
        }
    
    }
    
    .comp-2col, .comp-3col {
        display: flex;
        margin-left: -30px;
    
        @include breakpoint(medium only) {
            margin-left: -20px;
        }
    
        .u-col {
            padding-left: 30px;
    
            @include breakpoint(medium down) {
                padding-left: 25px;
            }
    
            &:first-child{
                
                @include breakpoint(small only) {
                    padding-left: 0;
                }
    
            }
    
        }
    
    }

    .js-mobileScroll{

        @include breakpoint(small only) {
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            margin-left: 0;
            padding: 10px 20px;
            margin-left: -20px;
            width: calc(100% + 40px);

            .comp-2col, .comp-3col{
                margin-left: 0;

                .u-col{
                    padding-left: 0;
                    padding-right: 20px;
                }
    

            }

        }

    }

    .u-col {

        @include breakpoint(medium) {
            opacity: 0;
            transform: translateY(15px);
            transition: all .7s $easing;
        }
    
        &.is-active{
            opacity: 1;
            transform: translateY(0);
        }
    
    }
    
    .chart__bar{
        transition: width .5s linear;
        transition-delay: .3s;
    
        &:not(.is-active){

            @include breakpoint(medium) {
                width: 0% !important;
            }

        }
    
    }
    
    .comp-3col {
    
        .u-col {
    
            @include breakpoint(medium) {
                flex: 0 0 33.33%;
                max-width: 33.33%;
            }
    
            @include breakpoint(small only) {
                min-width: 246px;
            }
    
        }
    
        .chart {
            margin-bottom: 10px;
        }
    
    }
    
    .comp-2col {
    
        .u-col {
    
            @include breakpoint(medium) {
                flex: 0 0 50%;
                max-width: 50%;
            }
    
            @include breakpoint(small only) {
                min-width: 326px;

                &--slide4__1{
                    min-width: 430px;
                }
    
                &--slide7__1{
                    min-width: 260px;
                }
    
                &--slide7__2{
                    min-width: 408px;
                }
    
            }
    
        }
    
        .chart {
            margin-right: 17px;
        }
    
        .comp-el__bar {
            margin-left: 15px;
    
            @include breakpoint(medium) {
                margin-left: 25px;
            }
    
            &.-no-margin {
                margin-left: 0;
            }
    
        }
    
    }
    
    .comp-el {
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        padding: 20px;
        height: 100%;
    
        @include breakpoint(xxlarge only) {
            padding: 30px 35px;
        }
    
        @include breakpoint(medium down) {
            padding: 20px 25px;
        }
    
        @include breakpoint(small only) {
            padding: 12px;
        }
    
        h3 {
            margin-left: 15px;
            font-size: 16px;
            line-height: 110%;
            margin-bottom: 0;

            @include breakpoint(small only) {
                margin-left: 10px;
            }
    
            @include breakpoint(large) {
                font-size: 18px;
            }
    
        }
    
        h4 {
            font-size: 14px;
            line-height: 110%;
    
            @include breakpoint(medium) {
                font-size: 16px;
            }
    
        }
    
        .tick-list li {
            font-weight: 400;
        }
    
        &__title {
            display: flex;
            margin-bottom: 20px;
            align-items: center;

            @include breakpoint(medium) {
                margin-bottom: 30px;
            }
    
            &.-no-margin {
                margin-bottom: 0;
            }
    
            img:not(.icon-info){
    
                @include breakpoint(small only) {
                    height: 20px;
                }
                
            }
    
        }
    
        &__bar {
            font-size: 14px;
    
            @include breakpoint(xxlarge) {
                font-size: 16px;
            }
    
            strong {
                color: #000;
            }
    
            &.-row {
                width: 100%;
                margin-top: 10px;
    
                strong {
                    margin-right: 3px;
                }

                span + strong{
                    margin-left: 3px;
                }
    
            }
    
            .chart {
                border: 1px solid $lighter-gray;
                border-radius: 4px;
                height: 20px;
    
                @include breakpoint(medium) {
                    height: 40px;
                }
    
                &__bar {
                    background-color: $unihall-blue;
                    height: 100%;
                    background-image: url('/assets/img/cost-of-living/bg-chart-stripe.png');
                    background-size: cover;
    
                    &.-grey {
                        background-color: $lighter-gray;
                    }
    
                }
    
            }
    
        }
    
    }

}

.colWidget-compare-introText{

    @include breakpoint(medium) {
        opacity: 0;
        transform: translateY(30px);
        transition: all .5s $easing;
        
        &.is-active{
            opacity: 1;
            transform: translateY(0);
        }

    }

}

.colWidget-result{

    .module-wrapper{
        padding-top: 40px;
        padding-bottom: 40px;

        @include breakpoint(medium) {
            @include interpolate('padding-top', $xxlarge, 50px, $maxWidth, 80px, 'ease');
            @include interpolate('padding-bottom', $xxlarge, 50px, $maxWidth, 80px, 'ease');
        }

    }

    .module-container {
        max-width: 1420px;
    }

}

.colWidget-compare-arrow{
    color: #FFFFFF;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    font-size: 14px;
    text-align: center;

    @include breakpoint(medium) {
        font-size: 16px;
        animation: 'bounce-in-bottom' .7s both;
        animation-delay: .6s;

        &.is-active{
            bottom: 60px;
        }

    }

    img {
        margin-top: 10px;
        animation: bounce 1s infinite;
    }
}

.colWidget-result-content{

    h2 {
        max-width: 1280px;
        margin-bottom: 15px;

        @include breakpoint(medium) {
            margin-bottom: 30px;
        }

    }

    p {
        max-width: 850px;
    }

    h2,
    p {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0 50px;
    }

}

.colWidget-txtimg{
    margin: 0 auto;
    position: relative;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 50px;

    @include breakpoint(large) {
        margin-top: 100px;
    }

    @include breakpoint(large) {

        &__txt {
            width: 44.7%;
            padding-left: 60px;
        }

        &__img {
            width: 50%;
        }

    }

    &__txt {

        @include breakpoint(medium down) {
            order: 2;
        }

    }

    &__img {

        @include breakpoint(medium down) {
            order: 1;
            margin-bottom: 30px;
        }

    }

    .btn{
        margin-top: 10px;

        @include breakpoint(small only) {
            width: 100%;
        }

    }

}

.colWidget-paging{
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 2;

    @include breakpoint(medium down) {
        width: 100%;
        justify-content: space-between;
        padding: 0 20px 10px;
        background-color: #fff;
    }

    @include breakpoint(large) {
        bottom: 20px;
    }

    .btn{
        white-space: nowrap;

        @include breakpoint(small only) {
            padding: 2px 24px 5px;
        }

    }

    ul {
        display: flex;
        margin: 0;
        margin-right: 38px;
        padding: 0;
        list-style: none;

        @include breakpoint(small only) {
            display: none;
        }

    }

    .step {
        width: 19px;
        height: 19px;
        display: block;
        border: 1px solid #B9B9B9;
        border-radius: 50%;
        margin-left: 20px;

        @include breakpoint(large) {
            margin-left: 38px;
        }

    }

    li {
        position: relative;

        &:not(:last-child) {

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -20px;
                width: 20px;
                height: 1px;
                border-top: dashed 1px #B9B9B9;

                @include breakpoint(large) {
                    right: -38px;
                    width: 38px;
                }

            }

        }

        &.is-complete {

            &:after {
                border-top: solid 1px #545B94;
            }

            .step {
                background-color: #545B94;
                border-color: #545B94;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    background-image: url('/assets/img/cost-of-living/icon-tick--white.svg');
                    background-size: cover;
                    width: 10px;
                    height: 8px;
                    position: absolute;
                    top: 5px;
                    left: 4px;
                }

            }

        }

        &.is-current {

            .step {
                border-color: #545B94;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    width: 11px;
                    height: 11px;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    background-color: #545B94;
                    border-radius: 50%;
                }

            }

        }

    }

}

.colWidget-feedback{
    background: #FFFFFF;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    position: absolute;
    z-index: 2;

    @include breakpoint(medium down) {
        height: 152px;
        overflow: hidden;
        bottom: 52px;
        left: 0;
        width: 100%;
        padding: 10px 22px;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
        border-radius: 12px 12px 0px 0px;

        &.is-active{
            height: auto;
            bottom: 0;
            z-index: 3;
        }
        
    }

    @include breakpoint(small only) {
        height: 74px;
        bottom: 35px;
        padding: 10px 20px 0;
    }

    @include breakpoint(large) {
        width: 270px;
        top: 30px;
        right: 30px;
        padding: 20px;
        transform: translateX(120%);
        transition: transform .3s $easing;

        &.is-visible{
            transform: translateX(0);
        }

    }

    @include breakpoint(xxlarge only) {
        top: 50px;
        right: 50px;
        @include interpolate('width', $xxlarge, 340px, $maxWidth, 528px, 'ease');
        @include interpolate('padding-top', $xxlarge, 20px, $maxWidth, 40px, 'ease');
        @include interpolate('padding-bottom', $xxlarge, 20px, $maxWidth, 40px, 'ease');
        @include interpolate('padding-left', $xxlarge, 35px, $maxWidth, 65px, 'ease');
        @include interpolate('padding-right', $xxlarge, 35px, $maxWidth, 65px, 'ease');
    }

    &.is-active{
        height: auto;            
    }

    &.has-overflow{

        .colWidget-feedback__btn{
            display: block;
        }

        .link{
            display: inline-block;
        }

    }

    &__btn, .link{

        @include breakpoint(medium) {
            display: none;
        }

    }

    &__info{
     
        @include breakpoint(medium only) {
            display: flex;
            justify-content: space-between;
        }

    }

    .greybox{

        @include breakpoint(medium only) {
            order: 2;
            margin: 0 0 0 20px;
            height: 116px;
        }

        @include breakpoint(small only) {
            width: calc(100% - 100px);
            min-width: calc(100% - 100px);
            padding: 0 8px;
            display: flex;
            align-items: center;
            text-align: left;
            margin: 0;

            strong{
                margin-bottom: 5px;
                margin-right: 5px;
                min-width: 73px;
            }

        }

        @include breakpoint(xlarge down) {
            margin-top: 0;
        }

    }

    .tick-list{
        position: relative;
        margin-bottom: 20px;

        @include breakpoint(medium) {

            &.has-overflow{
                max-height: 138px;
                overflow: hidden;
    
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 72px;
                    background: linear-gradient(rgba(255, 255, 255, 0.001), white); 
                }
    
            }

        }

        @include breakpoint(small only) {
            margin-top: 18px;
        }

        @include breakpoint(medium only) {
            margin-top: 30px;
        }

        li{

            &:not(.is-active){
                display: none;
            }

        }

    }

    img{
        max-width: 280px;
    }

    .link {
        font-weight: bold;
        font-size: 14px;
        line-height: 110%;
        text-decoration: underline;
        color: $unihall-blue;

        &:hover {
            text-decoration: none;
        }

        @include breakpoint(xxlarge only) {
            font-size: 16px;
        }

        @include breakpoint(medium down) {
            position: absolute;
            top: 15px;
            left: 20px;
        }

        @include breakpoint(small only) {
            left: auto;
            right: 38px;
            width: 50px;
            top: 20px;
        }

    }

    .u-text-center{

        @include breakpoint(xlarge down) {
            display: none;
        }

    }

    &__btn {
        margin-top: 20px;

        @include breakpoint(large only) {
            margin-top: 10px;
        }

        @include breakpoint(xxlarge) {
            border-top: 1px solid rgba(#000, .1);
            padding-top: 24px;
            margin-top: 24px;
        }

        .btn{

            @include breakpoint(small down) {
                width: 100%;
                margin-bottom: 20px;
            }

        }

    }
}

.colWidget-cards{
    list-style: none;
    margin: 60px 0 0 -32px;
    padding: 0;

    @include breakpoint(large) {
        display: flex;
    }

    >li {
        padding-left: 32px;

        @include breakpoint(large) {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            opacity: 0;
            transform: translateY(20px);
            transition: all .5s $easing;

            &.is-active{
                opacity: 1;
                transform: translateY(0);

                .colWidget-package img{
                    transition-delay: .15s;
                }

            }

            &:nth-child(2){
                transition-delay: .15s;

                .colWidget-package img{
                    transition-delay: .3s;
                }

            }

            &:nth-child(3){
                transition-delay: .3s;

                .colWidget-package img{
                    transition-delay: .45s;
                }
            }

        }

        @include breakpoint(medium down) {
            
            &:not(:first-child){
                margin-top: 40px;
            }

        }

    }

}

.colWidget-package{
    background: #fff;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    height: 100%;
    
    .inner {
        padding: 20px;
        z-index: 2;
        background: #fff;
        position: relative;

        @include breakpoint(medium) {
            padding-bottom: 107px;
        }

        @include breakpoint(xlarge) {
            padding-top: 40px;
            padding-left: 40px;
            padding-right: 40px;
        }

    }

    h3{

        @include breakpoint(large only) {
            font-size: 23px;
        }

    }

    p {
        font-weight: 400;
    }

    &__head {
        text-align: center;

        strong {
            color: #000;
        }

    }

    &__img {
        height: 166px;
        border-radius: 12px 12px 0px 0px;
        position: relative;

        &.-orange {
            background: #FFA866;

            img{
                top: -15px;
            }
        }

        &.-grey {
            background: #A1A3B5;

            img{
                top: -9px;
            }

        }

        &.-pink {
            background: #DE7676;

            img{
                top: -17px;
            }

        }

        img{
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);

            @include breakpoint(large) {
                transform: translate(-50%, 100%);
                transition: all .7s $easing;
                opacity: 0;
    
                .is-active &{
                    transform: translate(-50%, 0);
                    opacity: 1;
                }

            }
            
        }

    }

    .greybox {
        background: $darkest-gray;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        padding: 15px 35px;
        margin: 25px 0;

        sup {
            top: -1.2em;
            left: 4px;
            font-size: 21px;
            margin-right: 0;
        }

        strong {
            font-size: 60px;
            line-height: 100%;
            display: block;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0;
        }

    }

    &__features {

        p {
            font-size: 16px;
        }
        
    }

    &__btns {

        @include breakpoint(medium) {
            display: flex;
            position: absolute;
            bottom: 40px;
            left: 20px;
        }

        @include breakpoint(xlarge) {
            left: 40px;
        }

        @include breakpoint(small only) {
            margin-top: 30px;
        }

    }

    .btn{

        @include breakpoint(small only) {
            width: 100%;
        }

        &-primary {
            color: #fff;

            @include breakpoint(small only) {
                margin-bottom: 8px;
            }

        }

        &-secondary-hollow {

            @include breakpoint(medium) {
                margin-left: 15px;
            }

        }

        @include breakpoint(large only) {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 15px;
        }

    }

}

.colWidget-scroll-arrow{
    position: absolute;
    bottom: 31px;
    @include interpolate('left', $xxlarge, 50px, $maxWidth, 180px, 'ease');

    @include breakpoint(large down) {
        display: none;
    }

    img{
        animation: bounceRight 1.5s infinite;
    }

}

.colWidget-swipe-arrow{
    font-size: 13px;
    position: relative;
    margin-top: 10px;
    top: 5px;

    img{
        width: 20px;
        height: 10px;
        margin-left: 4px;
    }

    @include breakpoint(medium) {
        display: none;
    }

}

@-webkit-keyframes bounce {
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(-10px);
    }
    60% {
        -webkit-transform: scale(1.1);
    }
}
@keyframes bounce {
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-10px);
    }
    60% {
        transform: scale(1.1);
    }
} 

@-webkit-keyframes bounceRight {
    50%,
    80%,
    100% {
        -webkit-transform: translateX(0);
    }
    20% {
        -webkit-transform: translateX(10px);
    }
    60% {
        -webkit-transform: scale(1.1);
    }
}

@keyframes bounceRight {
    50%,
    80%,
    100% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(10px);
    }
    60% {
        transform: scale(1.1);
    }
} 

@-webkit-keyframes bounce-in-bottom {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
        bottom: -100px;
     }
     80% {
       opacity: 1;
       animation-timing-function: ease-in;
       bottom: 65px;
     }
     100% {
         opacity: 1;
         animation-timing-function: ease-in;
         bottom: 60px;
     }
}

@keyframes bounce-in-bottom {
    0% {
       opacity: 0;
       animation-timing-function: ease-in;
       bottom: -100px;
    }
    80% {
      opacity: 1;
      animation-timing-function: ease-in;
      bottom: 65px;
    }
    100% {
        opacity: 1;
        animation-timing-function: ease-in;
        bottom: 60px;
    }
}
  