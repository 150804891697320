.social-bar {
  text-align: center;
  @include breakpoint(medium) {
      text-align: left;
    }
  a {
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    @include hide-text;
    font-size: 1.2em;
    position: relative;
    margin-right: .7rem;
    vertical-align: top;
    margin-bottom: 1rem;
    transition: all .3s ease;
    &:hover {
        transform: scale(1.05);
        transition: all .5s;
        filter: drop-shadow(1px 1px 1px rgba(0,0,0,.3));
    }
    @include breakpoint(medium) {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    &.wide {
      text-indent: 0;
      width: 12rem;
      padding-top: 1.3em;
      font-size: 1em;
      .svg-icon {
        width: 100%;
        height:100%;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .svg-icon {
      font-size: .85em;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
