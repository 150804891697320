.result-item {
  position: relative;
  display: block;
  text-decoration: none;
  transition: background .25s ease;
  background: $white;
  border-bottom: 1px solid $light-gray;
  color: $black;
  padding: .5rem;
  overflow: hidden;
  + .result-item {
    margin-top: 1rem;
  }
  &:active,
  &:focus,
  &:hover {
    background: $white;
    box-shadow: 0 14px 14px -14px rgba(0,0,0,0.1);
    color: $black;
    img {
      filter: contrast(100%);
    }
  }
  &-img {
    filter: contrast(70%);
    max-width: 25rem;
    height: 100%;
    margin: -2rem -2rem 1rem -2rem;
    vertical-align: middle;
    float: left;
    transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
    @include breakpoint(medium) {
      max-width: 12rem;
      margin: -2rem 2rem -4rem -2rem;
    }
    + .result-item-content p {
      padding-top: 0;
    }
  }
  img {
    max-width: 100%;
    object-fit: cover;
    height: auto;
    min-height: auto;
    background-size: 100%;
    @include breakpoint(medium) {
      min-height: 200px;
    }
  }
  &-content {
    display: block;
    overflow: hidden;
    min-height:5em;
    @include breakpoint(medium) {
      padding-right: 4.8rem;
    }
    h1,
    h2,
    h3,
    h4 {
      @include fluid-type('font-size', 40rem, 80rem, 1.1rem, 1.333rem);
      font-family: $body-font-family;
      color: $black;
      line-height: 1.1;
      letter-spacing: 1px;
      padding-right: 4.8rem;
      font-weight: 600;
      @include breakpoint(medium) {
        padding-right: 0;
      }
    }
    p {
      @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
      padding-top: 1rem;
      @include breakpoint(medium) {
        padding-top: 0;
      }
    }
    p,
    ul {
      @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
      margin-bottom: 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      display: inline;
      &:after {
        display: inline-block;
        margin: 0 .2rem;
        content: '|';
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
      span {
        &:after {
          content: '; ';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }
  &-star {
    display:none;
    position: absolute;
    width: 4rem;
    height: 3rem;
    position: absolute;
    right: 0;
    color: $medium-gray;
    transition: color .25s ease, background .25s ease;
    .fa {
      display: block;
    }
    &-label {
      text-transform: uppercase;
      color: $dark-gray;
      font-weight: 600;
      font-size: .7em;
      opacity: 0;
      transition: opacity .25s ease;
    }
    &:hover {
      background:$light-gray;
      color: $black;
      .result-item-star-label {
        opacity: 1;
      }
    }
    &--selected {
      color: $black;
      &:hover {
        background:$light-gray;
        color: $black;
      }
    }
  }
  &-horizontal-star {
    display: none;
    @include clearfix;
    color: $black;
    transition: color .25s ease, background .25s ease;
    line-height: 1.6rem;
    margin-left: -.5rem;
    padding-left:.5rem;
    width: 100%;
    @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
    font-weight: 400;
    display: inline-block;
    .fa {
      display: inline-block;
      float: left;
      line-height: 0.6em;
    }
    &--selected {
      color: $black;
      font-weight: 400;
    }
    .result-item-star {
      height: 1.6rem;
      width: 5.3rem;
      position: absolute;
      right: 0;
      padding: .5rem;
      &-label {
        transition: opacity .25s ease;
        float:right;
        color: $dark-gray;
        height: 1.6rem;
        font-size: .85em;
        span {
          opacity: 0;
          text-transform: uppercase;
          font-size: .85em;
          color: $dark-gray;
          top: 0;
          display: inline-block;
          margin-top: .1rem;
          margin-left: .5rem;
        }
      }
    }

  }
  &--agent{
    .result-item-star {
      top: 0;
    }
    &-details {
      font-size: .8em;
      line-height: 1.6em;
      a {
        color: $anchor-color;
        font-weight: 600;
      }
      .fa {
        margin-right: .3rem;
      }
    }
    &-label {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  &--course {
    .result-item-star {
      top: 0;
    }
  }
  &--site-search {
    margin-bottom: 2rem;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      border-bottom: 3px solid $light-gray;
    }
    h4 {
      color: $dark-gray;
    }
  }
  &--scholarships {
    .result-item-star {
      top: 0;
    }
    &-details {
    margin-top: .5rem;
    line-height: 1;
    color: $dark-gray;
    &-label {
      text-transform: uppercase;
      color: $black;
      font-weight: 700;
      font-size: .8rem;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    &-static {
      overflow: hidden;
      > div {
        float: left;
        width: 50%;
        padding-left: $global-padding;
        border-right: 1px solid $medium-gray;
        display: block;
        &:nth-child(2) {
            padding-right:0;
            border-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }
        &:nth-of-type(3n) {
          border-right: none;
        }
       }
      }
    }
  }
    &--event {
      margin-top:1.5em;
      padding: 1.5rem;
      border: 1px solid $light-gray;
      max-height: 220px;
      overflow: hidden;
      position: relative;
      padding-bottom: 2.5em;
      .result-item-content {
        max-height: 190px;
        overflow: hidden;
        position: relative;
        padding-bottom: 2.5em;
      }
      h4 {
        color: $unihall-pink;
      }
    .result-item-event {
      position: absolute;
      font-family: $header-font-family;
      letter-spacing: $header-letterspacing*2;
      border: none;
      top: 0;
      right: 0;
      height: auto;
      width: auto;
      padding: .3rem .8rem;
      text-align: center;
      line-height: 1.4rem;
      color: $white;
      background: $unihall-pink;
    @include breakpoint(medium) {
        top:1rem;
        right: 0;
        padding: .3rem .8rem;
    }
    &-day {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.2rem;
    display: inline-block;
      }
    &-month {
        display: inline-block;
        text-transform: uppercase;
        font-family: $body-font-family;
        font-weight: 500;
        font-size: 1rem;

     }
    }
    .result-item-content {
        width: 100%;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2em;
          background: linear-gradient(to top, #eaeef2 0%, rgba(234, 238, 242, 0) 100%);
        }
        @include breakpoint(medium) {
          width: auto;
        }
     }
  }
  &--key-event {
      padding: 1.5rem;
      background: $unihall-pink;
      color: $white;
    h4 {
      color: $white;
    }
    &:active,
    &:focus,
    &:hover {
        background: $unihall-pink;
        color: $white;
        box-shadow: 0 14px 14px -14px rgba(0,0,0,0.1);
    }
  .result-item-key-event {
  font-family: $header-font-family;
  letter-spacing: $header-letterspacing*2;
  text-transform: uppercase;
  top: 1.2rem;
  left: auto;
  right: 0;
  float:right;
  height: auto;
  width: auto;
  padding: .3rem .8rem;
  text-align: center;
  line-height: 1.4rem;
  color: $white;
  background: $darkest-gray;
  margin: -1.5rem -1.5rem 0 1rem;
  @include breakpoint(medium) {
      top:1rem;
      left: 0;
      margin: 0 -1.5rem 0 0;
  }
  &-day {
      font-size: 1.4rem;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 0;
    }
  &-month {
        display: inline-block;
      }
  }
  .result-item-content {
      width: 100%;
          @include breakpoint(medium) {
          width: auto;
      }
   }
}
&--news {
  .result-item-key-event {
  float:left;
  font-family: $header-font-family;
  left: 0;
  height: 3.5rem;
  width: 3rem;
  text-align: center;
  line-height: 1.4rem;
  color: $primary-color;
  background: $light-gray;
  padding-top:.25rem;
  @include breakpoint(medium) {
      left: 0;
      margin-right:.8rem;
  }
  &-day {
      font-size: 1.4rem;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 0;
    }
  &-month {
          display: block;
      }
  }
.result-item-content {
    width: 82%;
        @include breakpoint(medium) {
        width: auFto;
    }
 }
}
&--program {
  @include clearfix;
  padding-right: 0;
  border-bottom:0;
  &-details-col {
    width: 100%;
    > span {
      display:block;
    }
    &:last-child {
      margin-top: $global-margin;
     }
  @include breakpoint(medium) {
    float:left;
    padding: $global-padding/2 $global-padding 0 $global-padding;
    &:first-child {
      width: 70%;
     }
     &:last-child {
       width: 30%;
       margin-top: 0;
      }
      > span {
        display:inline-block;
      }
    }
    h5 {
      @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
      margin-bottom:1rem;
      color: $darkest-gray;
      @include breakpoint(medium) {
      margin-bottom:0;
    }
    }

    &.col-time-first {
        @include clearfix;
        border-bottom:0;
          width: 100%;
          margin-top:0;
          > span {
            display:block;
          }
          &:last-child {
            margin-top: 0;
           }
        @include breakpoint(medium) {
          float:left;
          &:first-child {
            width: 20%;
            padding-left:0;
           }
           &:last-child {
             width: 80%;
             margin-top: 0;
            }
            > span {
              display:inline-block;
            }
          }
          h5 {
            @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
            margin-bottom:1rem;
            color: $darkest-gray;
            @include breakpoint(medium) {
            margin-bottom:0;
          }
          }
          h4 {
            margin-bottom:0;
            @include breakpoint(medium) {
            margin-bottom:inherit;
            }
          }
          p {
            padding-top:.5rem;
            @include breakpoint(medium) {
            padding-top:inherit;
            }
          }
         &.result-item-no-link {
          margin-top: 0;
          padding-right:4rem;
          h4 {
            color: $unihall-pink;
          }
          .result-item-content {
            padding-right:0;
            padding-bottom:0;
            min-height:3em;
            }
          }
          .filter-toggle-chevron {
            position: absolute;
            top: 2rem;
            width: 12em;
            left:0;
          }
    }
  }
   &.result-item-no-link {
    border-bottom:0;
    padding-right:0;
    .result-item-content {
      padding-right:0;
      }
    }
    &.program-time-first {
        margin-right: 0;
        border-bottom: 1px solid $light-gray;
      }
    .radio--myProgram {
      z-index: 10;
      position: absolute;
      margin-top: .4rem;
      margin-left: -.5rem;
      span {
        font-size: .9rem;
      }
      button {
        //margin-left: -.5rem;
        min-width: 11.5em;
        i {
          font-size: .75rem;
        }
      }
      label {
        font-size: .9rem;
      }
      .radio-date {
        font-weight: 600;
        color: $black;
      }
      .radio-select-list {
        & label:first-child {
          padding-top: .8em;
        }
      }
    }
  }
}


.result-item-no-link {
  position: relative;
  display: block;
  color: $black;
  padding: 0 0 .8rem .8rem;
  border-bottom: 1px solid $light-gray;
  margin: 1rem 0;
  &:last-child {
    border-bottom: none;
}
  @include breakpoint(medium) {
    padding: 0 2rem 1rem 0;
  }
  .result-item-content {
    display: block;
    overflow: hidden;
    min-height:5em;
    padding-bottom: 1rem;
    padding-right: 3rem;
    @include breakpoint(medium) {
            padding-right: 5rem;
    }
    h3,
    h4 {
      @include fluid-type('font-size', 40rem, 80rem, 1.1rem, 1.333rem);
      font-family: $body-font-family;
      color: $unihall-pink;
      line-height: 1.1;
      padding-right: 4.8rem;
      @include breakpoint(medium) {
        padding-right: 0;
      }
    }
    p,
    ul {
      @include fluid-type('font-size', 40rem, 80rem, .9rem, 1rem);
      margin-bottom: 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      display: inline;
      &:after {
        display: inline-block;
        margin: 0 .2rem;
        content: '|';
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
      span {
        &:after {
          content: '; ';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }
  &--agent{
    .result-item-star {
      top: 0;
    }
    &-details {
      font-size: .8em;
      line-height: 1.6em;
      a {
        color: $anchor-color;
        font-weight: 600;
      }
      .fa {
        margin-right: .3rem;
      }
    }
    &-label {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .result-item--agent-details-col {
    @include breakpoint(large) {
    @include clearfix;
    padding: $global-padding/2 $global-padding 0 $global-padding;
    float:left;
    width: 28%;
    margin-left: -($global-padding / 2);
    &:last-child {
      width: 44%;
    }
    &:first-child {
      margin-left: -$global-padding;
    span {
      display: inline-block;
    }
    }
  }
  > span,
  a {
    display: block;
  }
 }
 &.disable {
   opacity: .6;
   h4,
   .fa {
     color: $darkest-gray;
   }
   a,
   button {
     color: $darkest-gray;
     cursor: not-allowed;
     &:hover {
       text-decoration: none;
     }
   }
 }
}
