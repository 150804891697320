$grid-column-align-edge: false;

//Check list
.check-list,
.icon-list {
    margin-left: 1.9rem;
    li {
        position: relative;
        list-style: none;
        margin: 0.5rem;
        &:before {
            @include fa-icon();
            content: $fa-var-check;
            color: $primary-color;
            left: -1.9rem;
            position: absolute;
            padding: 0.25rem;
            top: 0.1rem;
            border-radius: 50%;
            font-size: 0.95rem !important;
        }
    }
}

.check-list {
    li:before {
        background-image: none; // overrides Foundation layer list styles
        width: auto;
        height: auto;
    }
}

//Icon list
.icon-list {
    &:before {
        @include fa-icon();
        content: "";
        color: $primary-color;
        left: -1.9rem;
        position: absolute;
        padding: 0.25rem;
        top: 0.1rem;
        border-radius: 50%;
        font-size: 0.95rem !important;
    }
}

//side-by-side
.side-by-side-list {
    display: inline-block;
    vertical-align: top;
    &:first-child {
        margin-bottom: 0;
        @include breakpoint(medium) {
            margin-right: 1rem;
        }
    }
}

//step-by-step & pathways list
.steps,
.pathways {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    counter-reset: list;
    @include clearfix;
    &.item-count-2 {
        @include grid-layout(
            2,
            ".steps-item, .steps-icon-item, .pathways-item",
            0
        );
        margin: 0;
        min-width: 20em;
        p {
            width: 100%;
        }
    }
    &.item-count-3 {
        @include grid-layout(
            3,
            ".steps-item, .steps-icon-item, .pathways-item",
            0
        );
        margin: 0;
        min-width: 60em;
        @include breakpoint(large) {
            min-width: rem-calc(map-get($breakpoints, "large"));
        }
    }
    &.item-count-4 {
        @include grid-layout(
            4,
            ".steps-item, .steps-icon-item, .pathways-item",
            0
        );
        margin: 0;
        min-width: 65em;
        @include breakpoint(large) {
            min-width: rem-calc(map-get($breakpoints, "large"));
        }
    }
    &.item-count-5 {
        @include grid-layout(
            5,
            ".steps-item,.steps-icon-item, .pathways-item",
            0
        );
        margin: 0;
        min-width: 90em;
        @include breakpoint(large) {
            min-width: rem-calc(map-get($breakpoints, "large"));
        }
    }
}

.steps {
    &-module {
        width: calc(100% + 2rem);
        position: relative;
        @include breakpoint(medium) {
            width: 100%;
            left: 0;
        }
    }
    &-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        margin-bottom: $global-margin * 2;
    }
    &-heading {
        position: absolute;
        text-align: center;
        top: 40%;
        left: 50%;
        margin: 0;
        z-index: 1;
        display: block;
        padding: 0;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    &-description {
        text-align: center;
        width: 100%;
    }
    &-item {
        counter-increment: list;
        position: relative;
        display: inline-block;
        padding: 5rem 2.5rem 1rem;
        text-align: center;
        vertical-align: top;
        font-size: 1rem;

        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        &:before {
            content: counter(list);
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 1;
            display: block;
            width: 4rem;
            height: 4rem;
            padding: 0.5rem 0 0 0;
            margin: 0 0 0 -2rem;
            color: $primary-color-light;
            background: $darkest-gray;
            font-family: $header-font-family;
            font-weight: 600;
            font-size: 2rem;
            border-radius: 50%;
            text-align: center;
        }
        &::after {
            background: url(/Assets/Foundation/Assets/img/next_arrow_grey.svg)
                no-repeat;
            display: block;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
            content: "";
            left: 100%;
            top: 15%;
        }
        &:last-child:after {
            display: none;
        }
    }
    &-icon-item {
        position: relative;
        display: inline-block;
        padding: 5rem 2.5rem 1rem;
        text-align: center;
        vertical-align: top;
        font-size: 1rem;

        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        &:after {
            background: url(/Assets/Foundation/Assets/img/next_arrow_grey.svg)
                no-repeat;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
            content: "";
            left: 100%;
            top: 15%;
        }
        &:last-child:after {
            display: none;
        }
        .steps-description {
            padding-top: 2.5rem;
        }
        .alt {
            &:after {
                background: url(/Assets/Foundation/Assets/img/next_arrow_white.svg)
                    no-repeat;
            }
        }
    }
}

.steps-icon {
    @include fluid-type("font-size", 40rem, 80rem, 2.5rem, 3rem);
    &-svg {
        font-size: 2.5em;
    }
    &-img {
        @include breakpoint("medium") {
            max-width: 80%;
        }
    }
}

.steps-icon-img,
.steps-icon-svg,
.steps-icon {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    display: block;
    width: 4rem;
    height: 4rem;
    padding: 0.5rem 0 0 0;
    margin: 0 0 0 -2rem;
    color: $primary-color;
}

.steps-item,
.steps-icon-item {
    &.alt {
        &:before {
            background: $white;
        }
        &::after {
            color: $primary-color-light;
            background: url(/Assets/Foundation/Assets/img/next_arrow_white.svg)
                no-repeat;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
            content: "";
            left: 100%;
            top: 15%;
        }
    }
}

.pathways {
    &-module {
        width: calc(100% + 2rem);
        position: relative;
        @include breakpoint(medium) {
            width: 100%;
            left: 0;
        }
    }
    &-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    &-heading {
        position: absolute;
        text-align: center;
        margin: 0;
        top: 20%;
        left: 50%;
        z-index: 1;
        display: block;
        padding: 0;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    &-description {
        text-align: center;
        width: 100%;
    }
    &-item {
        position: relative;
        display: inline-block;
        padding: 4.5rem 2.5rem 1rem;
        text-align: center;
        vertical-align: top;
        font-size: 1rem;
        width: 100%;

        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        &:after {
            background: url(/Assets/Foundation/Assets/img/next_arrow_grey.svg)
                no-repeat;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
            content: "";
            left: 100%;
            top: 15%;
        }
        &:last-child:after {
            display: none;
        }
    }
}

.pathways-item {
    &.alt {
        &::after {
            color: $primary-color-light;
            background: url(/Assets/Foundation/Assets/img/next_arrow_white.svg)
                no-repeat;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
            content: "";
            left: 100%;
            top: 15%;
        }
    }
}
