﻿/// <reference path="app" />
@mixin background-image () {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-size: cover;
  background-position: 50% 0%;
}

.photo-bg {
  &-opacity-level {
      &-light {
          opacity: 0.9;
      }
      &-medium {
          opacity: 0.7;
      }
      &-heavy {
          opacity: 0.4;
      }
    }
  }

//photo backgrounds
.photo-bg,
.photo-bg-dark,
.photo-bg-light {
  @include background-image ();

  &.focal-point-top-left {
    background-position: 10% 0%;
  }
  &.focal-point-top-left-center {
    background-position: 30% 0%;
  }
  &.focal-point-top-center {
    background-position: 50% 0%;
  }
  &.focal-point-top-right-center {
    background-position: 70% 0%;
  }
  &.focal-point-top-right {
    background-position: 90% 0%;
  }
  &.focal-point-middle-left {
    background-position: 10% 40%;
  }
  &.focal-point-middle-left-center {
    background-position: 30% 40%;
  }
  &.focal-point-middle-center {
    background-position: 50% 40%;
  }
  &.focal-point-middle-right-center {
    background-position: 70% 40%;
  }
  &.focal-point-middle-right {
    background-position: 90% 40%;
  }
  &.focal-point-bottom-left {
    background-position: 10% 100%;
  }
  &.focal-point-bottom-left-center {
    background-position: 30% 100%;
  }
  &.focal-point-bottom-center {
    background-position: 50% 100%;
  }
  &.focal-point-bottom-right-center {
    background-position: 70% 100%;
  }
  &.focal-point-bottom-right {
    background-position: 90% 100%;
  }
}

// photo background colours
.photo-bg-dark {
  h1,
  h2,
  h3,
  h4,
  h5 {
  text-shadow: 0 0 1rem rgba(0,0,0,.5);
  }
  opacity:0.8;
  filter:alpha(opacity=55); /* For IE8 and earlier */
  background-color: $darkest-gray;
 }


//text shadow
.text-dark{
    text-shadow: 0 0 2rem rgba(0, 0, 0, 1);
    .btn-primary {
       text-shadow: none;
    }
}


// General background colors
.light-bg {
    color: $darkest-gray;
    background: $light-gray;
    .module-title,
    .module-sub-title {
        color: $darkest-gray;
  }
}

.white-bg {
    color: $darkest-gray;
    background: $white;
}

.lightest-bg {
  background: $light-gray;
}

.alt-bg {
    background: $alt-bg-color;
    &-border {
        border: 1px solid $medium-gray;
    }
}

.green-bg {
    background: $primary-color;
    color: $darkest-gray;
    a {
        color: $darkest-gray;
    }
}

.yellow-bg {
  background: $unihall-yellow;
}
.blue-bg {
  background: $unihall-blue;
}
.purple-bg {
  background: $unihall-purple;
}
.pink-bg {
  background: $unihall-pink;
}

// text colours
.blue-bg,
.purple-bg,
.pink-bg {
  color: $white;
  &:hover {
    color: $white;
  }
}

.darkest-bg {
   background-color: darken($darkest-gray, 3%) !important;
   color: $white;
}

.module-container {
  .white-bg {
    color: $darkest-gray;
  }
  .dark-bg {
    color: $white;
  }
}

// General background colours & module background colours
.dark-bg,
.dark-photo-bg {
    background: $darkest-gray;
    color: $white;
    .module-title,
    .module-sub-title {
        color: $white;
    }
    .module-container a {
      color: $white;
    }
    .module-footer a {
      color: $primary-color-light;
    }

    blockquote
        {
        color: $white;
    }
    &-yellow {
      background: $unihall-yellow;
    }
    a {
      color: $white;
    }
    .cta-row-link,
    .key-info-banner-play-btn {
      border-color:$primary-color!important;
      color: $darkest-gray!important;
    &:hover {
      color: $white!important;
      //border-color: $primary-color!important;
    }
  }
  .tile-row-list-item {
    &:focus,
    &:hover {
      color: $darkest-gray;
    }
  }
}
