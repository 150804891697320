﻿/// <reference path="../app" />
/// Adds styles for an accordion container. Apply this to the same element that gets `data-accordion`.
@mixin accordion-container {
  list-style-type: none;
  background: $accordion-background;
  margin-#{$global-left}: 0;
}

@mixin accordion-custom-border {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: $light-gray;
}

/// Adds styles for the accordion item. Apply this to the list item within an accordion ul.
@mixin accordion-item {
  @include accordion-custom-border ();
  &:first-child >:first-child {
    border-radius: $global-radius $global-radius 0 0;
  }
  &:last-child >:last-child {
    border-radius: 0 0 $global-radius $global-radius;
  }
}

/// Adds styles for the title of an accordion item. Apply this to the link within an accordion item.
@mixin accordion-title {
  @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.2rem);
  display: block;
  padding: $accordion-item-padding;
  line-height: $global-lineheight;
  color: $dark-gray;
  font-weight: bold;
  position: relative;
  border: none;
  :last-child:not(.is-active) > & {
    border-radius: 0 0 $global-radius $global-radius;
    border-bottom: $accordion-content-border;
  }
  &:hover,
  &:focus {
    background: none;
    text-decoration: none;
    //color: scale-color($dark-gray, $lightness: 10%);
    color: $primary-color;
  }
  @if $accordion-plusminus {
    &::before {
      @include fa-icon();
      content: $fa-var-plus;
      position: absolute;
      margin-top: 0.6rem;
      color: $primary-color;
      //left: -.5rem;
      font-size: small;
      top: auto;
    }
    .is-active > &::before {
      @include fa-icon();
      content: $fa-var-times;
      font-size: small;
      color: $primary-color;
    }
  }
}

/// Adds styles for accordion content. Apply this to the content pane below an accordion item's title.
@mixin accordion-content {
  padding: $accordion-item-padding;
  display: none;
  border: $accordion-content-border;
  border-bottom: 0;
  background-color: $accordion-content-background;
  color: $accordion-content-color;
  :last-child > &:last-child {
    border-bottom: $accordion-content-border;
  }
}

.accordion {
  @include accordion-container;
  margin: 20px 0;
  &-module-sort {
    z-index: 1;
    text-align: right;
    margin: 1.5rem 0;
    .no-js & {
      display: none;
    }
    &ed-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}

.accordion-item {
  @include accordion-item;
  &:active {
    border: none;
  }
}

.accordion-title {
  @include accordion-title;
  padding: 1rem 1rem 1rem 1.8rem;
  &:focus {
    background-color: none;
  }
}

.accordion-content {
  @include accordion-content;
  @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.2rem);
  border: none;
  padding-top: .4rem;
}

.nested {
    margin-top: -.3rem;
    li {
    &:hover {
        background: $lighter-gray;
    }
    &.accordion-item:not(:last-child) {
       border-bottom: $accordion-content-border;
    }
    &.accordion-item:last-child {
       margin-top: -.2rem;
    }
  }
}

////// Vertical tabs
/// Mixins
@mixin vertical-tabs-transition() {
 -ms-transition: all .3s ease;
 -webkit-transition: all .3s ease;
 transition: all .3s ease;
}

/// Variables
$vertical-tab-text-color: darkgray;
$vertical-tab-text-color_active: black;
$vertical-tab-text-color_hover: black;
$vertical-tab-container-bg: white;
$vertical-tabs-min-width: 175px;
$vertical-tabs-width: 23%;
$vertical-tab-container-width: 70%;
$vertical-tab-container-min-width: 10px;

.vertical-tabs {
  ul {
  display: none;
  @include breakpoint(large) {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 10;
  	padding: 0;
    width: $vertical-tabs-width;
    min-width: $vertical-tabs-min-width;
  	list-style: none;
    @include vertical-tabs-transition;
  }
  li {
	  margin: 0;
	  cursor: pointer;
	  padding: 1rem;
	  line-height: 1;
	  color: #6a6a6a;
    text-align: left;
    font-weight: 500;
    background: white;
    border-bottom: 1px solid #efefef;
    &:hover {
      color: #000;
      @include vertical-tabs-transition;
    }
    &.active {
      border-bottom: 1px solid black;
    	color: $vertical-tab-text-color_active;
      @include vertical-tabs-transition;
       &:after {
        content:'\f105';
        font-family: FontAwesome;
        right: 1rem;
        position: absolute;
       }
    }
   }
 }

 &-wrapper {
   width: 85%;
   text-align: center;
   margin: 0 auto;
   background: transparent;
 }
}

.vertical-tab {
  &-container {
    display: block;
    width: 100%;
    border-top: none;
    @include breakpoint(large) {
      display: inline-block;
      vertical-align: top;
      position: relative;
      z-index: 20;
      width: $vertical-tab-container-width;
      min-width: $vertical-tab-container-min-width;
      text-align: left;
    	background: $vertical-tab-container-bg;
  }
 }

 &-content {
 	padding: 0 1rem 1rem 1rem;
   height: 100%;
   display: none;
 }

 &-drawer-heading {
   border-bottom: 1px solid #efefef;
   color: #6a6a6a;
   margin: 0;
   padding: 1rem;
   display: block;
   cursor: pointer;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   text-align: center;
   &:hover {
     color: $vertical-tab-text-color_hover;
   }
   @include breakpoint(large) {
     display: none;
   }
   .d_active {
   		background: #fff;
   	  color: $vertical-tab-text-color_active;
       border-bottom: 1px solid black;
        &:after {
         content:'\f107';
         font-family: FontAwesome;
         right: 1rem;
         position: absolute;
       }
   	}
  }
}
