// Module wrapper
.module-wrapper {
  @include clearfix;
    padding: (2*$global-padding) $global-padding;
    &.with-previous {
        padding-top: 0;
    }
    &.no-padding {
        padding: 0;
    }
    &.top-padding {
      padding-top: (6*$global-padding);
    }
    @include breakpoint('medium') {
        padding: (6*$global-padding) $global-padding;
         &.with-previous {
            margin-top: -(2*$global-padding);
        }
        &.no-padding {
            margin-top: 0;
            padding: 0;
        }
        &.top-padding {
          padding-top: (8*$global-padding);
        }
    }
    @include breakpoint('large') {
        padding: (6*$global-padding) $global-padding;
        &.with-previous {
            margin-top: -(4*$global-padding);
        }
        &.no-padding {
            margin-top: 0;
            padding: 0;
        }
        &.top-padding {
          padding-top: (8*$global-padding);
        }
    }
}

// Module container
.module-container {
    max-width: $global-width;
    margin: 0 auto;
    // position: relative;
    &.narrow {
        max-width: rem-calc(map-get($breakpoints, 'medium'));
    }
    &.mid-width {
         max-width: rem-calc(map-get($breakpoints, 'large'));
    }

    &.full-bleed {
        max-width: 100%;
    }
    &.callout {
     &-light {
        background-color:$light-gray;
        padding: .5rem;
    }
     &-dark {
        background-color:rgba(255,255,255,.8);
        padding: .5rem;
        color:$black;
        .module-title {
            color:$darkest-gray;
            }
        .module-sub-title {
            color: $black;
        }
        }
     }
}


// Module typography
.module-titles {
    margin-bottom: 2*$global-margin;
}

.module-title {
    text-align: center;
    margin-top: 0;
    font-weight: 600;
    color: $header-color;
    font-family: $header-font-family; // override for Foundation Layer
    .dark-photo-bg &,
    .dark-bg & {
        color: $dark-bg-heading-color;
        }
  &-modal {
  text-align: left;
  padding-bottom: $global-padding/2;
  margin-bottom: $global-margin*1.5;
  border-bottom: 1px solid $medium-gray;
  }
}

.module-sub-title {
   @include fluid-type('font-size', 40rem, 80rem, 1.125rem, 1.3125rem);
   font-family: $header-font-family; // override for Foundation Layer
   line-height: 1.5;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-weight: 300;
    text-transform: initial;
  &-modal {
    text-align: left;
    padding-bottom: $global-padding/2;
    margin-bottom: $global-margin*1.5;
  }
}

.module-footer {
    text-align: center;
    text-transform: initial;
    margin-top: $global-margin;
}
