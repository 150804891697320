// Item styling
.hero-carousel {
  &-item {
  position: relative;
  height: 80vh;
  min-height: 20rem; // for iOS and safari
  width: 100%;
  background-color: $black;
  opacity: .9;
  @include breakpoint(medium) {
    min-height: 25rem;
    overflow: hidden;
   }
  }

  &-module {
    height: 80vh;
    @include breakpoint(medium) {
      min-height: 40rem;
    }
  }

  &-bg {
    &-mobile {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include breakpoint(medium) {
        display: none;
      }
    }

    &-desktop {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include breakpoint(medium) {
        display: block;
        min-height: 25rem;
      }
    }
  }

  &-category {
    text-shadow: 0 0 1rem $black;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.1rem;
  }

  &-sub-title {
    p {
      @include fluid-type( 'font-size', 40rem, 80rem, 1rem, 1.3rem);
      line-height: 1.3;
      padding-bottom: 1rem;
    }

    .text-light & {
      color: $black;
    }

    .hero-carousel-icon {
      margin-right: 0.5rem;
    }
  }

  &-titles {
    line-height: 1.1;
    margin-bottom: 0.5em;
    position: absolute;
    margin-top: 3.5rem;
    z-index: 2;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    @include breakpoint(medium) {
      width: 40%;
      margin-top: 4.5rem;
    }
  }

  .btn-primary {
    @include fluid-type( 'font-size', 40rem, 80rem, 1.1rem, 1.3rem);
    background: $primary-color-light;
    color: $darkest-gray !important;
    border: $primary-color-light;
    padding: 1.5rem 3rem;

    &:focus,
    &:hover {
      color: $primary-color-light !important;
      background: $darkest-gray !important;
    }
  }
}

// Slider arrows
.PrevArrow {
  left: 2rem;
  border-right-color: transparent;
  border-top-color: transparent;
}
.NextArrow {
  right: 2rem;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
