// Unihall styles
.tile {
  display: block;
  position: relative;
  color: $white;
  text-shadow: 0 0 15px rgba(0,0,0,.95);
  height: 0;
  &-save-btn {
    font-size: 1rem;
    color: $white;
    overflow: hidden;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    text-decoration: none;
    z-index: 10;
    .fa {
      display: block;
      transition: transform 0.25s ease;
    }

    &:focus,
    &:hover {
      .fa {
        transform: translateY(-1em);
      }
    }
  }
  &:focus,
  &:hover {
    outline: none;
    color: $darkest-gray;
    text-shadow: none;
    .tile-content-info {
      opacity: 1;
      transition-delay: unset;
      transform: translate(-50%, -50%);
      transition-timing-function: ease-in;
    }
    .tile-content:after {
      opacity: 1;
      transition-delay: 0;
      transition-timing-function: ease-in;
    }
  }
  &-content {
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-size: cover;
    background-position: 50% 50%;
    &.focal-point-left {
      background-position: 0% 50%;
    }
    &.focal-point-left-center {
      background-position: 25% 50%;
    }
    &.focal-point-center {
      background-position: 50% 50%;
    }
    &.focal-point-right-center {
      background-position: 75% 50%;
    }
    &.focal-point-right {
      background-position: 100% 50%;
    }
    position: absolute;
    top: $global-margin/2;
    left: $global-margin/2;
    width: calc(100% - #{$global-margin});
    height: calc(100% - #{$global-margin});
    @include breakpoint(medium) {
      top: $global-margin*.65;
      left: $global-margin*.65;
      width: calc(100% - (#{$global-margin} * 1.3));
      height: calc(100% - (#{$global-margin} * 1.3));
    }
    @include breakpoint(large) {
      top: $global-margin*.8;
      left: $global-margin*.8;
      width: calc(100% - (#{$global-margin} * 1.6));
      height: calc(100% - (#{$global-margin} * 1.6));
    }
    &:before {
      content: '';
      background:url(/Areas/unihall/Assets/img/gradient.png) repeat-x 0 bottom;
      background-size: contain;
      background:linear-gradient(to top, rgba(0,0,0, 0) 0%, rgba(0,0,0, 0) 60%);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &:after {
      content: '';
      background:rgba($primary-color, .85);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .15s ease-in;
      opacity: 0;
      transition-delay: .05s;
      z-index: 3;
    }
    &-date {
      z-index: 6;
      position: absolute;
      top: 0;
      right: 0;
      background:$unihall-pink;
      padding: .5rem;
      font-weight:600;
      text-shadow: none;
      font-family: $header-font-family;
      text-align: center;
      line-height: 1;
      color: $white;
      &-day {
        font-weight: 600;
        margin-bottom: 0;
        font-size: 1.2rem;
        display: inline-block;
      }
      &-month {
        display: inline-block;
        text-transform: uppercase;
        font-family: $body-font-family;
        font-weight: 500;
        font-size: 1rem;
      }
    }
    &-title {
      @include fluid-type('font-size', 40rem, 80rem, 1.1rem, 1.25rem);
      color: $white;
      text-align: left;
      font-weight: 500;
      width:100%;
      letter-spacing: .5px;
      line-height: 1.1;
      position: absolute;
      bottom: $global-margin/1.2;
      left: $global-margin/1.5;
      right:$global-margin/1.5;
      z-index: 2;
      margin-bottom: 0;
      padding-top: .5rem;
      &:before {
        content: '';
        background:linear-gradient(to top, rgba(0,0,0, .75) 10%, rgba(0,0,0, 0) 100%);
        display: block;
        position: absolute;
        bottom: -$global-margin/1.2;
        left: -15%;
        width: 130%;
        height: 200%;
        z-index: -1;
      }
      &:after {
        @include chevron;
        padding-left: .7rem;
        display: inline;
        font-size: .9em;
      }
    }
    &-info {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      color: $darkest-gray;
      margin: 0;
      max-height: 90%;
      transform: translate(-50%, -40%);
      transition: opacity .25s ease, transform .25s ease-out;
      width: 80%;
      z-index: 4;
      font-weight: 600;
      font-size: 1.15rem;
      line-height: 1.2;
      text-shadow: none;
      overflow: hidden;
    }
  }
}
