.basic-info-banner {
  width: 100%;
  @include breakpoint(medium) {
    position: relative;
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  &-item,
  &-item-content {
    padding: $global-padding*2.5;
    width: 100%;
    height: 100%;
    @include breakpoint(medium) {
      float: left;
      width: 50%;
  }
}
&-module {
  max-width: 50em;
  margin: 0 auto;
}
  &-item-content {
    @include breakpoint(medium) {
      //position:absolute;
      left: 0;
      padding: $global-padding*3;
      display: flex;
      flex-direction: column;
      }
}
  &-img {
      display: block;
      padding: 0;
      width:100%;
      height: 18em;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
    	  display: block;
        padding-top: 56.25%; // 16:9 aspect ratio
      }
      @include breakpoint(medium) {
        height: 21em;
      }
  }
  &-container {
    width: 100%;
    @include breakpoint(medium) {
      display: block;
      padding: 0;
      width: 50%;
      float:right;
      position:relative;
      overflow: hidden;
      max-height:62vh;
      display: flex;
      flex-direction: column;
    }
  }
  &-btn {
    width: 100%;
    margin-top: $global-margin;
    @include breakpoint(large) {
      width: 80%;
    }
  }
  &-title {
    font-family: $header-font-family;
    @include fluid-type('font-size', 40rem, 80rem, 1.44rem, 2rem);
    @include breakpoint(medium) {
      line-height: $global-lineheight*.7;
    }
  }
   &-text {
        margin-top: .6rem;
        @include fluid-type('font-size', 40rem, 80rem, 1rem, 1.05rem);
    }
}


.alt {
  .basic-info-banner-item {
    @include breakpoint(medium) {
    float: right;
  }
  }
  .basic-info-banner-item-content {
    @include breakpoint(medium) {
    left: 50%;
  }
  }
  .basic-info-banner-container {
    @include breakpoint(medium) {
    float: left;
  }
  }
}

// Module colour variations
.dark-bg {
  .alt-bg,
  .white-bg {
    color: $dark-gray;
  }
}
