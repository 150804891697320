/// <reference path="app" />
///
$chevron: $fa-var-angle-right;
////// Sizes for buttons.
/// @type Map
$button-sizes: (
    tiny: 0.6rem,
    small: 0.75rem,
    default: 1.25rem,
    large: 1.35rem,
) !default;
// Expands a button to make it full-width.
// @param {Boolean} $expand [true] - Set to `true` to enable the expand behavior. Set to `false` to reverse this behavior.
@mixin button-expand($expand: true) {
    @if $expand {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    } @else {
        display: inline-block;
        width: auto;
        margin: $button-margin;
    }
}

/////// Unihall button styles
// Solid and Hollow buttons mixin
@mixin btn($background, $color) {
    border: 3px solid $background;
    background: $background;
    font-family: $body-font-family;
    &:hover,
    &:focus,
    &:active {
        background: none;
        border-color: $background;
        text-decoration: none;
    }
    // Sizes
    @each $size, $value in map-remove($button-sizes, default) {
        &.#{$size} {
            font-size: $value;
        }
    }
    &-hollow {
        background: none;
        border: 3px solid $background;
        font-family: $body-font-family;
        &:hover,
        &:focus,
        &:active {
            background: $background;
            color: $color;
            text-decoration: none;
            border-color: $background;
        }
        // Sizes
        @each $size, $value in map-remove($button-sizes, default) {
            &.#{$size} {
                font-size: $value;
            }
        }
    }
}

@mixin btn-solid($background, $color) {
    border: 3px solid $background;
    background: $background;
    font-family: $body-font-family;
    &:hover,
    &:focus,
    &:active {
        background: $background;
        border-color: $background;
        text-decoration: none;
    }
    // Sizes
    @each $size, $value in map-remove($button-sizes, default) {
        &.#{$size} {
            font-size: $value;
        }
    }
}
@mixin button-styles() {
    // Dark background button styles
    .dark-bg,
    .darkest-bg {
        background: $darkest-gray;
        .btn {
            color: $white;
            &-primary {
                color: $darkest-gray;
                &:hover,
                &:focus,
                &:active {
                    color: $white;
                }
            }
            &-secondary {
                @include btn($white, $darkest-gray);
                color: $darkest-gray;
                &:hover,
                &:focus,
                &:active {
                    color: $primary-color-light;
                }
                &-hollow {
                    color: $primary-color-light;
                }
            }
            &-yellow,
            &-aqua {
                color: $darkest-gray;
                &:hover,
                &:focus,
                &:active {
                    color: $white;
                }
            }
            &-purple,
            &-blue,
            &-pink {
                &:hover,
                &:focus,
                &:active {
                    color: $white;
                }
            }
        }
    }

    .photo-bg-dark {
        .btn {
            &-primary {
                @include btn-solid($white, $darkest-gray);
                &:hover,
                &:focus,
                &:active {
                    color: $darkest-gray;
                    background: $white;
                }
            }
        }
    }

    .green-bg,
    .yellow-bg {
        .btn-primary-solid,
        .btn-secondary-solid {
            @include btn($darkest-gray, $white);
            color: $white;
            &:hover,
            &:focus,
            &:active {
                color: $darkest-gray;
            }
        }
    }

    .pink-bg,
    .blue-bg,
    .purple-bg {
        .btn-primary-solid,
        .btn-secondary-solid {
            @include btn($white, $darkest-gray);
            &:hover {
                color: $white;
            }
        }
    }

    .green-bg,
    .yellow-bg {
        .btn-primary,
        .btn-secondary {
            @include btn($darkest-gray, $white);
            color: $white;
            &:hover,
            &:focus,
            &:active {
                color: $darkest-gray;
            }
            &-hollow {
                color: $darkest-gray;
            }
        }
    }

    .pink-bg,
    .blue-bg,
    .purple-bg {
        .btn-primary,
        .btn-secondary {
            @include btn($white, $darkest-gray);
            &-hollow {
                color: $white;
            }
            &:hover {
                color: $white;
            }
        }
    }

    // Base button styles
    .btn {
        color: $darkest-gray;
        padding: $button-padding;
        text-align: center;
        display: inline-block;
        letter-spacing: 0.5px;
        line-height: 1;
        font-weight: 600;
        -webkit-appearance: none;
        transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out,
            color 0.2s ease-in-out;
        &-primary {
            @include btn($primary-color-light, $darkest-gray);
            &:hover,
            &:focus,
            &:active {
                color: $darkest-gray;
            }
            &-hollow {
                color: $darkest-gray;
                padding: $button-padding;
                text-align: center;
                display: inline-block;
                letter-spacing: $header-letterspacing;
                line-height: 1;
                font-weight: 600;
                -webkit-appearance: none;
                transition: border-color 0.2s ease-in-out,
                    background 0.2s ease-in-out, color 0.2s ease-in-out;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: $darkest-gray;
                }
                &::after {
                    content: none;
                }
            }
        }
        &-secondary {
            @include btn($darkest-gray, $white);
            color: $primary-color-light;
            &:hover,
            &:focus,
            &:active {
                color: $darkest-gray;
            }
            &-hollow {
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: $primary-color-light;
                }
            }
        }
        &-yellow,
        &-aqua {
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: $darkest-gray;
            }
        }
        &-yellow {
            @include btn($unihall-yellow, $darkest-gray);
        }
        &-aqua {
            @include btn($unihall-aqua, $darkest-gray);
        }
        &-purple,
        &-blue,
        &-pink {
            color: $white;
            &:hover,
            &:focus,
            &:active {
                color: $darkest-gray;
            }
            &-hollow {
                &:hover,
                &:focus,
                &:active {
                    color: $white;
                }
            }
        }
        &-purple {
            @include btn($unihall-purple, $white);
        }
        &-blue {
            @include btn($unihall-blue, $white);
        }
        &-pink {
            @include btn($unihall-pink, $white);
        }
        .fa {
            margin-right: 0.5rem;
        }
    }

    .btn-chevron {
        &:after {
            @include chevron;
            vertical-align: -0.07em;
            padding-left: 0.5rem;
            transition: transform 0.3s ease;
        }
        &:hover,
        &:focus {
            &:after {
                transform: translateX(0.3rem);
            }
        }
    }

    // Video button
    .play-video {
        width: 6rem;
        height: 6rem;
        text-indent: 6rem;
        overflow: hidden;
        display: block;
        margin: 0 auto;
        white-space: nowrap;
        position: absolute;
        top: 35%;
        left: 45%;
        &:hover {
            &:before {
                transform: translate(-50%, -50%) scale(1.2);
            }
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            display: block;
            top: 50%;
            left: 50%;
        }
        &:before {
            border-radius: 50%;
            background: rgba($primary-color, 0.75);
            width: 4rem;
            height: 4rem;
            transform: translate(-50%, -50%);
            transition: transform 0.25s cubic-bezier(0.23, 0.54, 0.64, 1.53);
        }
        &:after {
            width: 0;
            height: 0;
            border: 1rem solid transparent;
            border-left-color: $white;
            transform: scale(1, 0.6) translate(-20%, -80%);
        }
    }

    // Button variations
    .btn-primary-solid {
        color: $darkest-gray;
        padding: $button-padding;
        text-align: center;
        display: inline-block;
        letter-spacing: $header-letterspacing;
        line-height: 1;
        font-weight: 600;
        -webkit-appearance: none;
        transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out,
            color 0.2s ease-in-out;
        @include btn($primary-color-light, $darkest-gray);
        &:hover,
        &:focus,
        &:active {
            color: $darkest-gray;
        }
        &::after {
            content: none;
        }
    }

    .btn-secondary-solid {
        color: $darkest-gray;
        padding: $button-padding;
        text-align: center;
        display: inline-block;
        letter-spacing: $header-letterspacing;
        line-height: 1;
        font-weight: 600;
        -webkit-appearance: none;
        transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out,
            color 0.2s ease-in-out;
        @include btn($darkest-gray, $white);
        color: $primary-color-light;
        &:hover,
        &:focus,
        &:active {
            color: $darkest-gray;
        }
        &::after {
            content: none;
        }
    }
}

// This is here to override the rich-text-content button class migration override in the Foundation layer:
// src\Custom\UWAFrontendAssets\CoreLayer\src\assets\scss\2_tools\mixins\_mix-rich-text-content.scss
// It is necessary to make sure the old Foundation layer button classes (that are still used in Unihall) don't inherit the Foundation layer
// style overrides and have a mix of UWA styling and Unihall styling. It is gross, but it's the cleanest way to handle this without re-applying individual button CSS.
@include button-styles();

.rich-text-content {
    @include button-styles();
}
