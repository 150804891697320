.horizontal-bar {
  @include clearfix;
  padding: 1rem;
  min-width: 45rem;
  position: relative;
  display: block;
   padding: 1.5rem 0;
  h3 {
    @include fluid-type('font-size', 40rem, 80rem, .88rem, 1.1rem);
	color: $dark-gray;
	line-height: 1.3;
  }
  p {
    @include fluid-type('font-size', 40rem, 80rem, .85rem, 1rem);
    line-height: 1.3;
  }
  div {
  .btn {
    font-size:.8rem;
  }
  }
  &-module {
    overflow-y: hidden;
    overflow-x: scroll;
    @include breakpoint('medium') {
      overflow-y: hidden;
      overflow-x: hidden;
     }
  }
}
