////// Vertical tabs
/// Mixins
@mixin vertical-tabs-transition() {
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/// Variables
$vertical-tab-text-color: $dark-gray;
$vertical-tab-text-color_active: $darkest-gray;
$vertical-tab-text-color_hover: $primary-color;
$vertical-tabs-min-width: 175px;
$vertical-tabs-width: 23%;
$vertical-tab-container-width: 70%;
$vertical-tab-container-min-width: 10px;

ul.vertical-tabs {
  display: none;
  @include breakpoint(large) {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 10;
    padding: 0;
    width: $vertical-tabs_width;
    min-width: $vertical-tabs_min_width;
    list-style: none;
    @include vertical-tabs-transition;
  }

  li {
    margin: 0;
    cursor: pointer;
    padding: 1rem;
    line-height: 1;
    color: #6a6a6a;
    text-align: left;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.8px;
    border-bottom: 1px solid $light-gray;

    &:hover {
      color: $primary-color;
      @include vertical-tabs-transition;
    }

    &.active {
      border-bottom: 3px solid $primary-color;;
      color: $vertical-tab_text_color_active;
      @include vertical-tabs-transition;

      &:after {
        content: '\f105';
        font-family: FontAwesome;
        right: 1rem;
        position: absolute;
        color: $primary-color;
      }
    }
  }
}

.vertical-tabs {
  &-module {
    .module-sub-title {
      margin-bottom: $global-margin;
    }
  }

  &-wrapper {
    width: 85%;
    text-align: center;
    margin: 2rem 0;
  }
}

.vertical-tab {
  &-container {
    display: block;
    width: 100%;
    border-top: none;
    margin: 2rem 0;
    @include breakpoint(large) {
      margin: 0;
      display: inline-block;
      vertical-align: top;
      position: relative;
      z-index: 20;
      width: $vertical-tab-container-width;
      min-width: $vertical-tab-container-min-width;
      text-align: left;
      background: transparent;
    }

    .active {
      display: block;
    }
  }

  &-content {
    padding: 0 1rem 1rem;
    margin-top: 1rem;
    color: $dark-gray;
    height: 100%;
    display: block;
    h2 {
      @include fluid-type('font-size', 40rem, 80rem, 1.3rem, 1.8rem);
    }
    @include breakpoint(large) {
      padding: 0 1rem 1rem 2rem;
    }
  }

  &-drawer-heading {
    text-transform: inherit;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.8px;
    border-bottom: 1px solid $light-gray;
    color: $dark-gray;
    margin: 0;
    padding: 1rem;
    display: block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;

    &:hover {
      color: $primary-color;
    }
    @include breakpoint(large) {
      display: none;
    }
    &.d_active {
      border-bottom: 3px solid $primary-color;;
      color: $vertical-tab_text_color_active;
      @include vertical-tabs-transition;

      &:after {
        content: '\f107';
        font-family: FontAwesome;
        right: 3rem;
        position: absolute;
        color: $primary-color;
      }
    }
  }
}

// Module colour variations
.alt-bg {
  ul.vertical-tabs li,
  .vertical-tab-drawer-heading {
  border-bottom: 1px solid $medium-gray;
  &.d_active {
    color: $vertical-tab-text-color_active;
    border-bottom: 2px solid $vertical-tab-text-color_active;
   }
  }
}
